import React, {useContext, useEffect, useState} from "react";
import { ThemeProvider } from '@emotion/react';

import NavbarAuth from "./NavBar/NavBar";
import {appThemeAkto} from "../themes/theme";

import Main from "./Main/Main";
import NavbarFront from "./NavBar/navbarS";




export default function LayoutFront({ titre,children}) {
    /**
     * Ici on créer la structure de l'application. C'est ici qu'on appelle le théme qui est défini dans le dossier 'themes'.
     * C'est ici qu'on intégre la logique si tu comptes créer un systéme de changement de théme (Light/Dark).
     */

    return (
        <>
            <ThemeProvider theme={appThemeAkto}>
               <NavbarFront titre={titre} />
                <Main content={children} />
            </ThemeProvider>
        </>
    );
}
