import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {deleteDelegation, getDelegation, updateDelegation} from "../../api/Api";
import {idID} from "@mui/material/locale";
import Link from "@mui/material/Link";
import {Chip, TextField} from "@mui/material";
import {convertDate} from "../../function/function";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useEffect, useState} from "react";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";



export default function DataGridDelegations({data}) {

    console.log('data delegation tabel ',data);
    const [dataRecieve,setDataRecieve] = useState(data.dataDelegations);
    let dataTable = dataRecieve;
    const navigate = useNavigate();
    const SkeletonTable = <LoadingIndicator/>
    const [loadingStatus, setLoadingStatus] = useState(true);
    useEffect(() => {

        setLoadingStatus(false);
    }, [dataTable])


    const  onDeleted =(e,param)=>{
        deleteDelegation(param._id).then(async r => {
            if (r.status === 200) {
                await navigate('/delegations')
            }
        });
    }
    const onUpdate=(e,param)=>{
        navigate(`/delegations/update/${param._id}`);
        // console.log(param)
    }

    const columns = [
        // { field: "_id", headerName: "ID", width: 200 },
        { field: "nom_delegation", headerName: "Nom de la délégation", width: 250 ,renderCell: (params) => {
                return (
                    <>
                        <Link
                            onClick={(e) => onUpdate(e, params.row)}
                            variant="contained"
                        >
                            {params.row.nom_delegation}
                        </Link>

                    </>

                );
            } },

        { field: "salutation", headerName: "Salutation", width: 300 ,renderCell:(params)=>{
                const parser = new DOMParser();
                const parsedContent = parser.parseFromString(params.value, 'text/html');
                const textContent = parsedContent.documentElement.textContent;
                return(
                    <>

                        <div style={{ whiteSpace: 'break-spaces'}}>
                            {textContent}
                        </div>


                    </>
                )
            }},
        { field: "signature_delegation", headerName: "Signature", width: 250 ,renderCell:(params)=>{
                const parser = new DOMParser();
                const parsedContent = parser.parseFromString(params.value, 'text/html');
                const textContent = parsedContent.documentElement.textContent;
                return(
                    <>

                        <div style={{ whiteSpace: 'break-spaces'}}>
                            {textContent}
                        </div>




                    </>
                )
            }},
        { field: "is_active", headerName: "Statut", width: 100 ,renderCell: (params) => {
                if (params.row.is_active) {
                    return (
                        <>
                            <Chip label="Activé" color="primary" variant="outlined" />

                        </>

                    );
                }else {
                    return (
                        <>
                            <Chip label="Désactivé" color="error" variant="outlined"/>

                        </>

                    );
                }

            } },
        { field: "updatedAt", headerName: "Date de modification", width: 220,renderCell: (params) => {
                console.log(params)
                return <div >{convertDate(params.row.createdAt)}</div>;
            }},
        { field: "Previews", headerName: "Prévisualisation",width: 200, renderCell: (params) => {
                return (
                    <>
                        <Link
                            href={`/preview/${params.row._id}`}
                            target="_blank"
                        >
                            <VisibilityIcon />
                        </Link>

                    </>

                );
            } },
        // { field: 'updatedAt', headerName: 'Modifier', width: 150, renderCell: (params) => {
        //         return (
        //             <>
        //                 <Button
        //                     onClick={(e) => onUpdate(e, params.row)}
        //                     variant="contained"
        //                 >
        //                     Update
        //                 </Button>
        //
        //             </>
        //
        //         );
        //     } },
        // { field: 'Supprimer', headerName: 'Supprimer', width: 150, renderCell: (params) => {
        //         return (
        //             <>
        //                 <Button
        //                     onClick={(e) => onDeleted(e, params.row)}
        //                     variant="contained"
        //                 >
        //                     Supprimer
        //                 </Button>
        //
        //             </>
        //
        //         );
        //     } }
    ];
    console.log('dataTable', dataTable);

    return (
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <div style={{ height: '100%', maxWidth: '100%', margin: 'auto', overflow: 'auto' }}>
                    <DataGrid
                        rows={dataTable}
                        getRowId={(row) => row._id}
                        columns={columns}
                        hideFooter={true}
                        style={{ width: '100%' }}
                         getRowHeight={() => 200}
                        autoHeight={true}
                        // autoHeight={true}
                    />
                </div>

            )}
        </>
    )

}
