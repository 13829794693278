import Skeleton from "@mui/material/Skeleton";
import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import DataGridCategorie from "../Categorie/DataGridCategorie";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getDelegation} from "../../api/Api";
import DataGridDelegations from "./DataGridDelegations";
import Layout from "../../layout/Layout";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";
import jwtDecode from "jwt-decode";


export default  function Delegations() {
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [valid,setValid]=useState(false);
    const [dataDelegations, setDataDelegations] = useState();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(jwtDecode(localStorage.getItem('token')));
    const { isAuthenticated } = useContext(AuthContext)
    const SkeletonTable = <LoadingIndicator
    />

    useEffect(() => {
        getDelegation()
            .then(resp => {
                if (userInfo.delegation) {
                    setValid(false)
                } else {
                    setValid(true)

                }
                setDataDelegations(resp.data);
                console.log("use effect delegations")
                console.log(resp.data)
                setLoadingStatus(false);
            }).catch(err => {
            console.log('error from fetching categories', err);
        })
    }, [])


    return(
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <Layout isAuthenticated={isAuthenticated}>
                    <Grid container spacing={5} >
                        <Grid item xs={12} className="containerTitre">
                            <Typography variant="h1">Délégations</Typography>
                        </Grid>
                        <Grid item xs={8}>
                        </Grid>
                        {valid && (
                            <Grid item xs={4} className="gridCustom" style={{paddingBottom:"50px"}}>
                                <Button className="with-after_icon-akto btn-primary-filled" onClick={() => navigate('/delegations/ajout')}>Ajouter une délégation</Button>
                            </Grid>
                        )}


                        <Grid container spacing={5} justify="center" alignItems="center"  className={"tabuser"}>

                        <Grid item xs={12}>
                            <DataGridDelegations data={{dataDelegations}}/>
                        </Grid>
                            <Grid container spacing={5} justify="center" alignItems="center" style={{paddingTop:"200px"}}>
                            </Grid>

                        </Grid>
                    </Grid>
                    </Layout>
                </>

            )}
        </>
    )

}
