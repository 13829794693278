import { createTheme } from "@mui/material/styles";
import "../assets/fonts/stylesheet.css"
import { frFR } from '@mui/material/locale';
export const appThemeAkto = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#1d4851",
        },
        secondary: {
            main: "#00a79b",
        },
        error: {
            main: "#f94336",
        },
        warning: {
            main: "#fd9a04",
        },
        info: {
            main: "#2095f3",
        },
        success: {
            main: "#4fb353",
        },
        navbar:{
            main: "#ffffff",
        },
        divider: "rgba(6,6,6,0.12)",
        background: {
            default: '#fafafb',
            paper: '#fffffa',
        },
    },
    typography: {
        fontFamily: ['Mont','icon'].join(','),
        fontSize: 16,

    },
},frFR);

/**
 * Le théme pour Akto déjà tout prêt. Si t'as besoin de changer une police d'écriture, tu le fais ici.
 * Si tu as besoin de rajouter une nouvelle police d'écriture, tu la rajoutes via les @font-face en CSS dans le dossier '../index.css'.
 * Je n'ai pas changé les couleurs d'erreur, d'information, de danger et de succés. Ils sont accessible depuis les propriétés indiqué dans le théme.
 * Ici le type du théme est défini sur Light, comme tu peux le deviner tu peux en créer un second pour créer un théme sombre et faire un switch pour passer de l'un à l'autre. Le type pour le théme sombre est 'dark'.
 */
