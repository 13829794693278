import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getCategories, getFiche, getLien} from "../../api/Api";
import Skeleton from "@mui/material/Skeleton";
import ParentAndChildCheckbox from "./ParentAndChildCheckbox";
import ViewWithCheckboxes from "./ParentAndChildCheckbox";
import ViewWithIndeterminateCheckboxes from "./ParentAndChildCheckbox";
import LayoutFront from "../../layout/LayoutFront";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";


export default function FicheV2() {

    const [fiches ,setFiches]=useState();
    const [categories,setCategorie]=useState();
    const [allData,setAllData]=useState()
    const [loadingStatus, setLoadingStatus] = useState(true);
    const SkeletonTable = <Skeleton sx={{bgcolor: 'grey.900'}}
                                    variant="rectangular"
                                    width={210}
                                    height={118}
    />



    function categorizeFiches(categories, fiches) {
        const categorizedFiches = categories.reduce((result, category) => {
            result[category.titre] = [];
            return result;
        }, {});

        fiches.forEach(fiche => {
            const matchingCategories = fiche.categories.filter(ficheCategory =>
                categories.some(category => category._id === ficheCategory._id)
            );

            matchingCategories.forEach(matchingCategory => {
                categorizedFiches[matchingCategory.titre].push(fiche);
            });
        });

        return categorizedFiches;
    }
    async function fetchData() {
        const data1 = await  getLien().then( async r=>{
                //console.log(r.data)
                setFiches(r.data)
             return r.data
            }
        );
        const data2 = await  getCategories().then(async r=>{
            setCategorie(r.data)
            return r.data
        });
        return Promise.all([data1, data2]).then(([data1, data2]) => {
            console.log(data1)
            setAllData(categorizeFiches(data2,data1));

        });
    }

    useEffect(() => {

       fetchData().then(async r=>{
          setLoadingStatus(false)
       })

        //setAllData(categorizeFiches(categories,fiches));

    }, []);

    console.log(allData)

    return(
        <>
        {loadingStatus === true ? (
            <LoadingIndicator/>
        ) : (
            <>
                <LayoutFront titre={"Fiches métiers"}>
                    <Grid container className="containerCheckBoX" style={{marginTop:"40px"}}>
                        <h2>Veuillez sélectionner les fiches à envoyer</h2>
                    </Grid>

                    <div className="header-separator">
                        <div className="header-separator-left"></div>
                        <div className="header-separator-angle"></div>
                        <div className="header-separator-right"></div>
                    </div>

                    <Grid container className="containerCheckBoX">
                        <ViewWithIndeterminateCheckboxes data={allData}/>
                    </Grid>
                </LayoutFront>

            </>

        )}
        </>
    )

}