import {CircularProgress} from "@mui/material";

const LoadingIndicatorLogin = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only"><CircularProgress /></span>
            </div>
        </div>
    );
};

export default LoadingIndicatorLogin;