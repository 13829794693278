import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Path from './routes/Path';
import "react-datepicker/dist/react-datepicker.css";


const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Path />);

