import Box from "@mui/material/Box";
import LogoAkto from "../../assets/images/svg-akto.svg";


export default function LogoHeader() {
  return (
    <>
      <Box
        display='flex'
        justifyContent='center'
        sx={{
         // bgcolor: "white",
         // boxShadow: 1,
          pt: 2,
          pb: 2,
          pl: 4,
          pr:8,
          width: 100,
          height: 40
        }}
      >
        <img src={LogoAkto} alt="Logo Akto" />
      </Box>
    </>
  );
}
