import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import {
    apiSenPassword, apiSenPasswordAdm,
    check,
    deleteCategories,
    getCategories,
    getDelegation,
    getLoginStatus,
    getStats
} from "../../api/Api";
import Layout from "../../layout/Layout";
import LayoutFront from "../../layout/LayoutFront";
import { Controller, useForm } from "react-hook-form";
import { Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import MySwal from "sweetalert2";
import jwtDecode from "jwt-decode";

export default function PasswordAdm() {
    const navigate = useNavigate();
    const { userId, token } = useParams();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const { register, control, formState: { errors }, handleSubmit } = useForm();
    const [email, setEmail] = useState("");
    const [reType, setRetype] = useState("");
    const [error , setError]=useState(false);



    const reTypeValidation = (value) => {
        console.log(email)
        // Validate that re-typed password matches the original password
        if (value !== email) {
            return "Les emails doivent correspondre.";
        }
    };

    useEffect(() => {
        // check(userId,token).then(r=>{
        //     if(r.status === 200){
        //         setError(false)
        //     }else {
        //         setError(true)
        //     }
        // })

    }, [])

    const onSubmit = (data) => {

        console.log(data);

          MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Valider',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
              apiSenPasswordAdm(data).then(async r => {
                  console.log(r)
                if (r.status === 200) {
                    if(r.data.role === "admin"){
                        await MySwal.fire({
                            title: 'E-mail envoyé',
                            icon: 'error',
                            confirmButtonColor: '#00a79b',
                            denyButtonColor:'#00a79b'

                        })
                        await navigate('/gestion')
                    }
                    else {
                        await navigate('/')
                    }
                }else {
                    await MySwal.fire({
                        title: 'Compte non trouvé',
                        icon: 'error',
                        confirmButtonColor: '#00a79b',
                        denyButtonColor:'#00a79b'

                    })
                }
            });
        })


    };

    return (
        <LayoutFront titre={"Récuperation de mot de passe"}>
            <>
                {error === true ? (
                    <Grid container alignItems="center" justifyContent="center" rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
                        <Grid item xs={12} alignItems="center" justifyContent="center" className="boxAjustement">
                            <p style={{ marginTop: '10px',paddingTop:"30px" }}>Le lien a expiré, merci de contacter votre administrateur.</p>
                        </Grid>
                    </Grid>

                ) : (
                    <>
            <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: "50vh" }} rowSpacing={5} columnSpacing={{ xs: 4, sm: 4, md: 4 }}>
                <form className="borderForm" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container alignItems="center" justifyContent="center" rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
                        <Grid item xs={12} alignItems="center" justifyContent="center" className="boxAjustement">
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={email}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label="Adresse E-mail"
                                        id="outlined"
                                        name="titre"
                                        className="boxForm"
                                        value={value}
                                        style={{minWidth:400}}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            onChange(e);
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                                rules={{
                                    required: "L'email est obligatoire",
                                    validate: value => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) || "L'email doit être au bon format"
                                }}
                            />
                        </Grid>


                        <Grid item xs={12} alignItems="center" justifyContent="center" className="boxAjustement">
                            <Button className="with-after_icon-akto btn-primary-filled-step2" type="submit">Soumettre</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
                    </>

                )}
            </>


        </LayoutFront>
    );
}

