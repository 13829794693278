import React, { useEffect, useState } from 'react';
import {BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell, LabelList} from 'recharts';
import {Select, MenuItem, Typography, Box, Button} from '@mui/material';
import {
    exportFichesByProfil,
    exportProfilsByBranches,
    generateXlsxUser,
    getCategories,
    getTypologie,
    popularite
} from "../api/Api.jsx";
import {useTheme} from "@mui/material/styles";


const Popularite = ({ startDate, endDate, selectedDelegations }) => {
    const colors = [
        "#49F0B1",
        "#36C3A8",
        "#0048BE",
        "#001B9C",
        "#7F45C4",
        "#592B8F",
        "#FF708F",
        "#C93857",
        "#FFA807",
        "#FF6707"
    ];
    const [profils, setProfils] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedProfil, setSelectedProfil] = useState("");
    const [selectedCategorie, setSelectedCategorie] = useState("");
    const [categorieTop10, setCategorieTop10] = useState([]);
    const [liensTop10, setLiensTop10] = useState([]);
    const theme = useTheme();
    // Premier effet pour la récupération des profils et des catégories
    useEffect(() => {
        getTypologie().then(response => {
            setProfils(response.data);
        });

        getCategories().then(response => {
            setCategories(response.data);
        });
    }, []);

// Second effet pour la mise à jour du "Top 10 des branches"
    useEffect(() => {
        popularite(startDate, endDate, selectedDelegations, selectedProfil, selectedCategorie)
            .then(response => {
                const correctedData = response.data.categorieTop10.map(item => {
                    console.log("gggggg")
                    console.log(item.categorie)
                    console.log("kkkkkkkk")
                    if (item.categorie == 'Hotels, cafés, restaurants') {
                        return {...item, categorie: 'Hôtels, cafés, restaurants'};
                    }
                    return item;
                });

                const sortedCategorieTop10 = [...correctedData].sort((a, b) => String(a.categorie).localeCompare(String(b.categorie)));
                setCategorieTop10(sortedCategorieTop10);
            });

        console.log('branche -----------------------------');
        console.log(categorieTop10);
        console.log('branche -----------------------------');
    }, [startDate, endDate, selectedProfil, selectedDelegations]);


// Troisième effet pour la mise à jour du "Top 10 des fiches"
    useEffect(() => {
        popularite(startDate, endDate, selectedDelegations, selectedProfil, selectedCategorie)
            .then(response => {
                const sortedLiensTop10 = [...response.data.liensTop10].sort((a, b) => a.Fiche.localeCompare(b.Fiche));
                setLiensTop10(sortedLiensTop10);
            });

        console.log('Liens -----------------------------')
        console.log(liensTop10)
        console.log('liens -----------------------------')
    }, [startDate, endDate, selectedProfil, selectedCategorie, selectedDelegations]);


    function handleDownloadClick() {
        exportProfilsByBranches(startDate, endDate,selectedProfil)
            .then(response => {
                console.log('Fichier Excel téléchargé avec succès');
            })
            .catch(error => {
                console.error('Erreur lors du téléchargement du fichier Excel:', error);
            });
    }
    useEffect(() => {
        console.log("selectedCategorie a changé :", selectedCategorie);
    }, [selectedCategorie]);
    useEffect(() => {
        setSelectedCategorie(""); // Réinitialise la valeur de `selectedCategorie` à une chaîne vide
    }, [selectedProfil]);
    const handleChangeProfil = (event) => {
        setSelectedProfil(event.target.value);
        setSelectedCategorie(""); // Réinitialiser la valeur de `selectedCategorie` à une chaîne vide
    }


    function handleDownloadClick2() {
        console.log("handleDownloadClick2 a été appelé, selectedCategorie est :", selectedCategorie);
        exportFichesByProfil(startDate, endDate,selectedProfil,selectedCategorie)
            .then(response => {
                console.log('Fichier Excel téléchargé avec succès');
            })
            .catch(error => {
                console.error('Erreur lors du téléchargement du fichier Excel:', error);
            });
    }


    console.log(selectedProfil)
    return (
        <Box sx={{ textAlign: 'center',width:'100%'}}>
            <Select
                value={selectedProfil}
                onChange={handleChangeProfil}
                displayEmpty
            >
                <MenuItem value="">
                    <em>Tous les profils</em>
                </MenuItem>
                {profils.map(profil => (
                    <MenuItem key={profil._id} value={profil._id}>{profil.titre}</MenuItem>
                ))}
            </Select>

            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <Box sx={{ marginRight: '8em'}}>
                    <Typography variant="h6" style={{paddingBottom:'2.7em'}}>Top 10 des branches</Typography>
                    {
                        categorieTop10.length > 0 ? (

                    <BarChart className="chart" width={500} height={500} layout='vertical' data={categorieTop10} margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }} >
                        <YAxis type="category" dataKey="categorie" width={350} tickLine={{transform: 'translate(-10px)'}} tickFormatter={(value) => value.split('/').join(' / ')}
                               tick={{dy: 0}} />
                        <XAxis type="number" />
                        <Tooltip formatter={(value, name) => [value, "Nombre"]} />
                        <Bar dataKey="count">
                            <LabelList dataKey="count" position="right" fill="#000" />
                            {categorieTop10.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Bar>
                    </BarChart>
                        ) : (
                            <p>Aucun résultat.</p>
                        )
                    }
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    </Box>

                    <Button
                        className="with-after_icon-akto btn-primary-filled"
                        onClick={handleDownloadClick}
                        style={{ marginTop: theme.spacing(2) }}
                    >
                        Télécharger la liste complète
                    </Button>
                </Box>
                <Box>
                    <Typography variant="h6">Top 10 des fiches</Typography>

                    <Select
                        value={selectedCategorie}
                        onChange={event => setSelectedCategorie(event.target.value)}
                        displayEmpty
                    >
                        <MenuItem value="">
                            <em>Toutes les branches</em>
                        </MenuItem>
                        {categories.map(categorie => (
                            <MenuItem value={categorie._id}>{categorie.titre}</MenuItem>
                        ))}
                    </Select>

                    {
                        liensTop10.length > 0 ? (
                            <BarChart className="chart" width={500} height={500} layout='vertical' data={liensTop10} margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}>
                                <YAxis type="category" dataKey="Fiche" width={350} tickLine={{transform: 'translate(-10px)'}} tickFormatter={(value) => value.split('/').join(' / ')}
                                       tick={{fontSize: 14, dy: 0}} />
                                <XAxis type="number" />
                                <Tooltip />
                                <Bar dataKey="Nombre">
                                    <LabelList dataKey="Nombre" position="right" fill="#000" />
                                    {liensTop10.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Bar>
                            </BarChart>
                        ) : (
                            <p>Aucun résultat.</p>
                        )
                    }

                    <Button
                        className="with-after_icon-akto btn-primary-filled"
                        onClick={(event) => handleDownloadClick2(event)}
                        style={{ marginTop: theme.spacing(2) }}
                    >
                        Télécharger la liste complète
                    </Button>

                </Box>



            </Box>
        </Box>
    );
}

export default Popularite;
