import {useContext, useState} from "react";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute.jsx";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";
import {Grid, Typography} from "@mui/material";
import DatePicker from "react-datepicker";
import Button from "@mui/material/Button";
import {envoispardelegation, getDelegation} from "../../api/Api.jsx";
import HistogramV2 from "../../components/HistogramV2.jsx";
import PieChartComponent from "../../components/PieChartComponent.jsx";
import TableMetierDistribue from "../../components/TableMetierDistribue.jsx";
import PieChartEnvoieMail from "../../components/PieChartEnvoieMail.jsx";
import TableConseillerEnvoie from "../../components/TableConseillerEnvoie.jsx";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import Box from "@mui/material/Box";
registerLocale('fr', fr)
setDefaultLocale('fr');

export default function StatDelegation() {
    const [stats,setStats]=useState([]);
    const { isAuthenticated } = useContext(AuthContext);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const SkeletonTable = <LoadingIndicator
    />
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dataFilterByDate,setDataFilterByDate]=useState([])
    const [delegation,setDelegation]=useState([]);
    const [viewsCheckBoxDelegation,setViewsCheckBoxDelegation]=useState(false)
    const [dataFilter,setDataFilter]=useState()
    const [isChecked ,setIsChecked]=useState(false)
    const [dataEnvoie, setDataEnvoie]=useState();
    const handleChangeStartDate = (date) => {
        setStartDate(date);

        // Si la date de fin est antérieure à la date de début, la mettre à jour
        if (endDate < date) {
            setEndDate(date);
        }
    };

    const handleChangeEndDate = (date) => {
        // Si la date de fin est antérieure à la date de début, empêcher la mise à jour
        if (date >= startDate) {
            setEndDate(date);
        }
    };
    const valideDate = () => {

        envoispardelegation(startDate,endDate)
            .then(resp => {
             setDataEnvoie(resp.data);
             setIsChecked(true);
            })
            .catch(err => {
                // Gérer les erreurs ici
            });
    };

    return(
       <>
           <Grid container spacing={5} justify="center" alignItems="center" className="gridCustom2">
               <Grid item xs={12} style={{textAlign:"center"}}>
                   <Typography variant="h6" style={{paddingBottom:'20px'}}>Définition de la période</Typography>
               </Grid>
               <Grid item  style={{paddingTop:"10px"}}>
                   <Typography>Date de début de période</Typography>
                   <DatePicker
                       showIcon
                       selected={startDate}
                       onChange={handleChangeStartDate}
                       dateFormat="dd/MM/yyyy"
                       locale="fr"
                   />
               </Grid>
               <Grid item  style={{paddingTop:"10px"}}>
                   <Typography>Date de fin de période</Typography>
                   <DatePicker
                       showIcon
                       selected={endDate}
                       onChange={handleChangeEndDate}
                       dateFormat="dd/MM/yyyy"
                       minDate={startDate}
                       locale="fr"// Empêcher la sélection de dates antérieures à la date de début
                   />
               </Grid>
               <Grid item>
                   <Button className="with-after_icon-akto btn-primary-filled" onClick={() => valideDate()}>Valider</Button>
               </Grid>
           </Grid>
           { isChecked &&
               <Box width={1}>


               <Grid container spacing={0} justify="center" alignItems="center" direction="column" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop:'40px' }}>

                   <Grid item xs={12} sm={6} md={4} className="gridCustomStat">
                       <Grid container spacing={0} justify="center" alignItems="center">
                           <Grid item xs={12}>
                               <Typography variant="h6">Répartition des envois (e-mails) par délégation</Typography>
                           </Grid>
                           <Grid item xs={4} sm={6} md={12}>
                           <PieChartEnvoieMail data={dataEnvoie}/>
                           </Grid>
                       </Grid>
                   </Grid>

                   <Grid item xs={12} sm={6} md={4} className="gridCustomStat" style={{paddingTop:'40px'}}>
                       <Grid container spacing={5} justify="center" alignItems="center">
                           <Grid item xs={12}>
                               <Typography variant="h6">Répartition des envois (e-mails) par conseiller</Typography>
                           </Grid>
                           <Grid container xs={12} sm={12} md={12} className="center-content">
                                <TableConseillerEnvoie startDate={startDate} endDate={endDate}/>
                           </Grid>

                       </Grid>
                   </Grid>

               </Grid>
               </Box>
           }
       </>
    )
}