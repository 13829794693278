import {getCategories, getLien} from "../../api/Api";
import {useContext, useEffect, useState} from 'react';
import Skeleton from '@mui/material/Skeleton';
import {Grid, Typography, Item} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {appThemeAkto} from "../../themes/theme";
import Button from "@mui/material/Button";
import formulaireCat from "../../components/CRUD_categorie/formulaireCat";
import {useNavigate} from "react-router-dom";
import DataGridLiens from "./DataGridLiens";
import Layout from "../../layout/Layout";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";

// import Link  from "react-router-dom";

export default function Liens() {

    const [dataLiens, setDataLiens] = useState();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext)

    const SkeletonTable = <LoadingIndicator
    />
    async function fetchData() {
        const data1 = await  getLien().then( async r=>{
                //console.log(r.data)
                return r.data
            }
        );
        return Promise.all([data1]).then(([data1, data2]) => {
            console.log(data1)
            setDataLiens(data1);

        });
    }


    useEffect( () => {
        fetchData().then(r => {
            setLoadingStatus(false);
        })

    }, [])




    return (
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <Layout isAuthenticated={isAuthenticated}>
                        <Grid container spacing={5} justify="center" alignItems="center">
                            <Grid item xs={12} className="containerTitre">
                                <Typography variant="h1">Documents</Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4} className="gridCustom" style={{paddingBottom:"50px"}}>
                                <Button className="with-after_icon-akto btn-primary-filled" onClick={() => navigate('/documents/ajout')}>Ajouter un document</Button>
                            </Grid>
                            <Grid container spacing={5} justify="center" alignItems="center" >

                            <Grid item xs={12} justify="center" alignItems="center" style={{paddingBottom:"200px"}}  className={"tabuser"}>
                                <DataGridLiens data={{dataLiens}} />
                            </Grid>

                            </Grid>
                            <Grid container spacing={5} justify="center" alignItems="center" >
                            </Grid>
                        </Grid>
                    </Layout>


                </>

            )}
        </>
    );

}