export default function Main({content}) {
    /**
     * Composant qui gérer l'affichage du contenu des pages. Les composants enfants sont directements affichés entre les balises main. 
     * C'est ici que tu peux paramétrer le container principal de ton application. 
     */
    return (
        <>
            <main>
                {content}
            </main>
        </>
        )
}