import React, {useContext, useEffect} from "react";
import {Checkbox, Collapse, FormControlLabel, Grid, List, ListItem} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import MySwal from "sweetalert2";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";



function ViewWithIndeterminateCheckboxesPreviews({ data }) {
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState({});
    const [expanded, setExpanded] = React.useState(null);
    const { register, control,formState: { errors }, handleSubmit } = useForm();
    const navigate = useNavigate();
    const { categoriePrev } = useContext(AuthContext);


    const handleExpand = (categoryId) => {
        setExpanded(expanded === categoryId ? null : categoryId);
    };
    const handleClick = () => {
        setOpen(!open);
    };

    const handleCheck = (event) => {
        console.log(event.target.checked)
        setSelected({
            ...selected,
            [event.target.value]: event.target.checked,
        });
        categoriePrev({
            ...selected,
            [event.target.value]: event.target.checked,
        })

    };

    function getCheckedChildren(obj) {
        return Object.entries(obj).filter(([key, value]) => value === true).map(([key]) => key);
    }
    const onSubmit = (data,e) => {

        console.log("formstep1");

        let choix=getCheckedChildren(selected)
        console.log(Object.keys(selected).length
        )

        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Valider',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if(Object.keys(selected).length === 0){
                    MySwal.fire({title: 'Aucune catégorie(s) sélectionner(s)',confirmButtonColor: '#00a79b'})
                }else {
                    navigate(`/step2/${JSON.stringify(selected)}`)
                }

               // history.push(`/step2/${encodeURIComponent(JSON.stringify(selected))}`);

            }
        })
    }


        return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <List >
                {Object.keys(data).map((category,index) => (
                    <React.Fragment key={category}>
                        {data[category].length > 0 && (
                            <ListItem className="boxFil" onClick={() => handleExpand(category)}>

                                <FormControlLabel
                                    className={
                                        Object.values(data[category]).some(fiche => fiche.is_default_select)
                                            ? 'checkbox-parent-selected categorieParent'
                                            : 'categorieParent'
                                    }


                                    control={
                                        <Checkbox
                                            indeterminate={
                                                Object.values(data[category]).some((fiche) => selected[fiche._id]) &&
                                                !Object.values(data[category]).every((fiche) => selected[fiche._id])
                                            }
                                            checked={
                                                Object.values(data[category]).every((fiche) => selected[fiche._id])
                                            }
                                        />
                                    }
                                    label={category}
                                />


                                {data[category].length > 0 && open ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                        )}
                        {data[category].length !== 0 && (
                            <Collapse in={expanded === category} timeout="auto" unmountOnExit className="listEnfant">
                                <List component="div" disablePadding >
                                    {data[category].map((fiche,index) => (
                                        <ListItem key={index} style={{marginBottom: "-30px"}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selected[fiche._id] !== undefined ? selected[fiche._id] : fiche.is_default_select}

                                                        // checked={selected[fiche._id]}
                                                       // checked={selected[fiche._id] || fiche.is_default_select}
                                                        onChange={handleCheck}
                                                        value={fiche._id}
                                                    />
                                                }
                                                label={fiche.titre}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        )}

                    </React.Fragment>
                ))}
            </List>
            {/*<Grid item xs={12} alignItems="center"*/}
            {/*      justifyContent="center" className="boxAjustement">*/}
            {/*    <Button className="with-after_icon-akto btn-primary-filled" type="submit">Valider la liste</Button>*/}

            {/*</Grid>*/}
        </form>

    );
}
export default ViewWithIndeterminateCheckboxesPreviews;
