import {useEffect, useState} from "react";
import {
    generateXlsxUser,
    getDelegation,
    top10Users
} from "../api/Api.jsx";
import {Button, Grid, Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useTheme} from "@mui/material/styles";


export default function TableConseillerEnvoie({ startDate, endDate }) {
    const [delegation,setDelegation]=useState([]);
    const [delegationParam,setDelegationParam]=useState(1);
    const [data, setData] = useState([]);
    const theme = useTheme();
    const columns = [
        { field: 'Conseiller', headerName: 'Conseiller', flex: 1, filterable: true },
        { field: 'delegation', headerName: 'Délégation', flex: 1, filterable: true },
        { field: 'count', headerName: 'Nombre', flex: 1, filterable: true },
        { field: 'repartition', headerName: 'Répartition (%)', flex: 1, filterable: true },
    ];
    const frFR = {
        // Root
        noRowsLabel: 'Aucun résultat.',
        noResultsOverlayLabel: 'Aucun résultat.',

        // Density selector toolbar button text
        toolbarDensity: 'Densité',
        toolbarDensityLabel: 'Densité',
        toolbarDensityCompact: 'Compacte',
        toolbarDensityStandard: 'Standard',
        toolbarDensityComfortable: 'Confortable',

        // Columns selector toolbar button text
        toolbarColumns: 'Colonnes',
        toolbarColumnsLabel: 'Choisir les colonnes',

        // Filters toolbar button text
        toolbarFilters: 'Filtres',
        toolbarFiltersLabel: 'Afficher les filtres',
        toolbarFiltersTooltipHide: 'Cacher les filtres',
        toolbarFiltersTooltipShow: 'Afficher les filtres',
        toolbarFiltersTooltipActive: (count) =>
            count > 1 ? `${count} filtres actifs` : `${count} filtre actif`,

        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: 'Rechercher…',
        toolbarQuickFilterLabel: 'Recherche',
        toolbarQuickFilterDeleteIconLabel: 'Supprimer',

        // Export selector toolbar button text
        toolbarExport: 'Exporter',
        toolbarExportLabel: 'Exporter',
        toolbarExportCSV: 'Télécharger en CSV',
        toolbarExportPrint: 'Imprimer',
        toolbarExportExcel: 'Télécharger pour Excel',

        // Columns panel text
        columnsPanelTextFieldLabel: 'Chercher une colonne',
        columnsPanelTextFieldPlaceholder: 'Titre de la colonne',
        columnsPanelDragIconLabel: 'Réorganiser la colonne',
        columnsPanelShowAllButton: 'Tout afficher',
        columnsPanelHideAllButton: 'Tout cacher',

        // Filter panel text
        filterPanelAddFilter: 'Ajouter un filtre',
        filterPanelRemoveAll: 'Tout supprimer',
        filterPanelDeleteIconLabel: 'Supprimer',
        filterPanelLogicOperator: 'Opérateur logique',
        filterPanelOperator: 'Opérateur',
        filterPanelOperatorAnd: 'Et',
        filterPanelOperatorOr: 'Ou',
        filterPanelColumns: 'Colonne',
        filterPanelInputLabel: 'Valeur',
        filterPanelInputPlaceholder: 'Filtrer la valeur',

        // Filter operators text
        filterOperatorContains: 'contient',
        filterOperatorEquals: 'est égal à',
        filterOperatorStartsWith: 'commence par',
        filterOperatorEndsWith: 'se termine par',
        filterOperatorIs: 'est',
        filterOperatorNot: "n'est pas",
        filterOperatorAfter: 'postérieur',
        filterOperatorOnOrAfter: 'égal ou postérieur',
        filterOperatorBefore: 'antérieur',
        filterOperatorOnOrBefore: 'égal ou antérieur',
        filterOperatorIsEmpty: 'est vide',
        filterOperatorIsNotEmpty: "n'est pas vide",
        filterOperatorIsAnyOf: 'fait partie de',
        'filterOperator=': '=',
        'filterOperator!=': '!=',
        'filterOperator>': '>',
        'filterOperator>=': '>=',
        'filterOperator<': '<',
        'filterOperator<=': '<=',

        // Header filter operators text
        headerFilterOperatorContains: 'Contient',
        headerFilterOperatorEquals: 'Est égal à',
        headerFilterOperatorStartsWith: 'Commence par',
        headerFilterOperatorEndsWith: 'Se termine par',
        headerFilterOperatorIs: 'Est',
        headerFilterOperatorNot: "N'est pas",
        headerFilterOperatorAfter: 'Postérieur',
        headerFilterOperatorOnOrAfter: 'Égal ou postérieur',
        headerFilterOperatorBefore: 'Antérieur',
        headerFilterOperatorOnOrBefore: 'Égal ou antérieur',
        headerFilterOperatorIsEmpty: 'Est vide',
        headerFilterOperatorIsNotEmpty: "N'est pas vide",
        headerFilterOperatorIsAnyOf: 'Fait partie de',
        'headerFilterOperator=': 'Est égal à',
        'headerFilterOperator!=': "N'est pas égal à",
        'headerFilterOperator>': 'Est supérieur à',
        'headerFilterOperator>=': 'Est supérieur ou égal à',
        'headerFilterOperator<': 'Est inférieur à',
        'headerFilterOperator<=': 'Est inférieur ou égal à',

        // Filter values text
        filterValueAny: 'tous',
        filterValueTrue: 'vrai',
        filterValueFalse: 'faux',

        // Column menu text
        columnMenuLabel: 'Menu',
        columnMenuShowColumns: 'Afficher les colonnes',
        columnMenuManageColumns: 'Gérer les colonnes',
        columnMenuFilter: 'Filtrer',
        columnMenuHideColumn: 'Cacher',
        columnMenuUnsort: 'Annuler le tri',
        columnMenuSortAsc: 'Tri ascendant',
        columnMenuSortDesc: 'Tri descendant',

        // Column header text
        columnHeaderFiltersTooltipActive: (count) =>
            count > 1 ? `${count} filtres actifs` : `${count} filtre actif`,
        columnHeaderFiltersLabel: 'Afficher les filtres',
        columnHeaderSortIconLabel: 'Trier',

        // Rows selected footer text
        footerRowSelected: (count) =>
            count > 1
                ? `${count.toLocaleString()} lignes sélectionnées`
                : `${count.toLocaleString()} ligne sélectionnée`,

        // Total row amount footer text
        footerTotalRows: 'Total de lignes :',

        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,

        // Checkbox selection text
        checkboxSelectionHeaderName: 'Sélection',
        checkboxSelectionSelectAllRows: 'Sélectionner toutes les lignes',
        checkboxSelectionUnselectAllRows: 'Désélectionner toutes les lignes',
        checkboxSelectionSelectRow: 'Sélectionner la ligne',
        checkboxSelectionUnselectRow: 'Désélectionner la ligne',

        // Boolean cell text
        booleanCellTrueLabel: 'vrai',
        booleanCellFalseLabel: 'faux',

        // Actions cell more text
        actionsCellMore: 'Plus',

        // Column pinning text
        pinToLeft: 'Épingler à gauche',
        pinToRight: 'Épingler à droite',
        unpin: 'Désépingler',

        // Tree Data
        treeDataGroupingHeaderName: 'Groupe',
        treeDataExpand: 'afficher les enfants',
        treeDataCollapse: 'masquer les enfants',

        // Grouping columns
        groupingColumnHeaderName: 'Groupe',
        groupColumn: (name) => `Grouper par ${name}`,
        unGroupColumn: (name) => `Arrêter de grouper par ${name}`,

        // Master/detail
        detailPanelToggle: 'Afficher/masquer les détails',
        expandDetailPanel: 'Afficher',
        collapseDetailPanel: 'Masquer',

        // Row reordering text
        rowReorderingHeaderName: 'Positionnement des lignes',

        // Aggregation
        aggregationMenuItemHeader: 'Agrégation',
        aggregationFunctionLabelSum: 'Somme',
        aggregationFunctionLabelAvg: 'Moyenne',
        aggregationFunctionLabelMin: 'Minimum',
        aggregationFunctionLabelMax: 'Maximum',
        aggregationFunctionLabelSize: "Nombre d'éléments",
    }

    useEffect(() => {
        top10Users(startDate, endDate, delegationParam)
            .then((resp) => {
                setData(resp.data.map((item, index) => ({ ...item, id: index, Conseiller: `${item.firstName} ${item.lastName}` })));
            })
            .catch((err) => {});

        getDelegation()
            .then((resp) => {
                setDelegation(resp.data);
            })
            .catch((err) => {});
    }, [startDate, endDate, delegationParam]);

    function handleDownloadClick() {
        generateXlsxUser(startDate, endDate,delegationParam)
            .then(response => {
                console.log('Fichier Excel téléchargé avec succès');
            })
            .catch(error => {
                console.error('Erreur lors du téléchargement du fichier Excel:', error);
            });
    }

    function handleDelegationChange(event){
        setDelegationParam(event.target.value);
        console.log(delegationParam)
    }

    return (
        <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
                <FormControl>
                    <InputLabel id="delegation-select-label">Délégation</InputLabel>
                    <Select
                        style={{ height: '40px' }}
                        labelId="delegation-select-label"
                        id="delegation-select"
                        value={delegationParam}
                        label="Toutes les délégations"
                        onChange={handleDelegationChange}
                    >
                        <MenuItem value={1} selected>Toutes les délégations</MenuItem>
                        {delegation.map((del, index) => (
                            <MenuItem key={index} value={del._id}>{del.nom_delegation}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <p style={{marginTop:'40px',marginBottom:'-10px'}}>Top 10</p>
            <Grid item style={{ height: 600, width: 1200, marginTop: theme.spacing(2) }}>
                <DataGrid localeText={frFR} rows={data} columns={columns} pageSize={10} hideFooter={true} disableSelectionOnClick autoHeight />
            </Grid>
            <Grid item>
                <Button
                    className="with-after_icon-akto btn-primary-filled"
                    onClick={handleDownloadClick}
                    style={{ marginTop: theme.spacing(2) }}
                >
                    Télécharger la liste complète
                </Button>
            </Grid>
        </Grid>
    );
}
