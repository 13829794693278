import React, {useState} from "react";
import {
    Checkbox,
    Collapse,
    FormControlLabel,
    Grid,
    List,
    ListItem,
} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import MySwal from "sweetalert2";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";

function ViewWithIndeterminateCheckboxes({data}) {
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(null);
    const {register, control, formState: {errors}, handleSubmit} = useForm();
    const navigate = useNavigate();
    // const [selected, setSelected] = useState({})

    const [selected, setSelected] = useState(() => {
        const initialSelected = {};

        Object.keys(data).forEach((category) => {
            data[category].forEach((fiche, index) => {
                console.log(fiche)
                if(fiche.is_default_select){
                    initialSelected[fiche._id] = true;
                }



            });
        });
        console.log(initialSelected)
        return initialSelected;
    });

    const handleExpand = (categoryId) => {
        setExpanded(expanded === categoryId ? null : categoryId);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const handleParentCheck = (event, category) => {
        event.stopPropagation();

        const newSelected = {...selected};
        const allChildrenSelected = Object.values(data[category]).every(
            (fiche) => selected[fiche._id]
        );

        if (allChildrenSelected) {
            Object.values(data[category]).forEach((fiche) => {
                newSelected[fiche._id] = false;
            });
        } else {
            Object.values(data[category]).forEach((fiche) => {
                newSelected[fiche._id] = true;
            });
        }

        setSelected(newSelected);
    };

    const handleCheck = (event) => {
        const targetId = event.target.value;
        const isChecked = !selected[targetId];

        setSelected({
            ...selected,
            [targetId]: isChecked,
            [event.target.value]: event.target.checked,
        });

        const category = Object.keys(data).find((category) =>
            data[category].some((fiche) => fiche._id === targetId)
        );
    };

    function getCheckedChildren(obj) {
        return Object.entries(obj)
            .filter(([key, value]) => value === true)
            .map(([key]) => key);
    }

    const onSubmit = (data, e) => {
        console.log("formstep1");

        let choix = getCheckedChildren(selected);
        console.log(selected);

        MySwal.fire({
            title: "Voulez-vous confirmer votre action ?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Valider",
            denyButtonText: `Annuler`,
            confirmButtonColor: "#00a79b",
            denyButtonColor: "#BABABA",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if (Object.keys(selected).length === 0 || Object.values(selected).every(value => !value)) {
                    MySwal.fire({
                        title: "Aucune catégorie n'a été sélectionnée",
                        confirmButtonColor: "#00a79b",
                    });
                }
                else {
                    navigate(`/step2/${JSON.stringify(selected)}`);
                }
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{width: "70%"}}>
            <List>
                {Object.keys(data).map((category, index) => (
                    <React.Fragment key={category}>
                        {data[category].length > 0 && (
                            <ListItem
                                className="boxFil"
                                onClick={() => handleExpand(category)}
                            >
                                <div>
                                    <FormControlLabel
                                        onClick={(event) => event.stopPropagation()}
                                        className={
                                            Object.values(data[category]).some(
                                                (fiche) => fiche.is_default_select
                                            )
                                                ? "checkbox-parent-selected categorieParent"
                                                : "categorieParent"
                                        }
                                        control={
                                            <Checkbox
                                                indeterminate={
                                                    Object.values(data[category]).some(
                                                        (fiche) => selected[fiche._id]
                                                    ) &&
                                                    !Object.values(data[category]).every(
                                                        (fiche) => selected[fiche._id]
                                                    )
                                                }
                                                checked={
                                                    index === 0
                                                        ? selected[data[category][0]._id] !== false
                                                            ? true
                                                            : false
                                                        : Object.values(data[category]).every(
                                                            (fiche) => selected[fiche._id]
                                                        )
                                                }
                                                onChange={(event) =>
                                                    handleParentCheck(event, category)
                                                }
                                            />
                                        }
                                        label={category}
                                    />
                                    {data[category].length > 0 && open ? (
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                // top: "50%",
                                                // transform: "translateY(-50%)",
                                            }}
                                        >
<ExpandLess/>
</span>
                                    ) : (
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                // top: "50%",
                                                // transform: "translateY(-50%)",
                                            }}
                                        >
<ExpandMore/>
</span>
                                    )}
                                </div>
                                <Collapse
                                    in={expanded === category}
                                    timeout="auto"
                                    unmountOnExit
                                    className="listEnfant"
                                >
                                    <List component="div" disablePadding>
                                        {data[category].map((fiche, index) => (
                                            <ListItem key={index} style={{marginBottom: "-30px"}}>
                                                <FormControlLabel
                                                    className="checkbox-label"
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                selected[fiche._id] !== undefined
                                                                    ? selected[fiche._id]
                                                                    : fiche.is_default_select
                                                            }
                                                            onChange={handleCheck}
                                                            value={fiche._id}
                                                        />
                                                    }
                                                    label={fiche.titre}
                                                    onClick={(event) => {
                                                        event.stopPropagation(); // Arrête la propagation de l'événement click
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </ListItem>
                        )}
                    </React.Fragment>
                ))}
            </List>
            <Grid
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                className="boxAjustement"
                style={{paddingBottom:"20px"}}
            >
                <Button className="with-after_icon-akto btn-primary-filled" type="submit" >
                    Valider la liste
                </Button>
            </Grid>
        </form>
    );
}

export default ViewWithIndeterminateCheckboxes;

