import {DataGrid, gridStringOrNumberComparator} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {deleteCategories, deleteDelegation, sendRecovery} from "../../api/Api";
import Link from "@mui/material/Link";
import {Chip} from "@mui/material";
import {convertDate} from "../../function/function";
import MySwal from "sweetalert2";



export default function DataGridUser({data}) {


    const dataRecieve = data.dataUser;
    let dataTable = dataRecieve;
    const navigate = useNavigate();


    const  onDeleted =(e,param)=>{
        deleteCategories(param._id).then(async r => {
            if (r.status === 200) {
                await navigate('/comptes')
            }
        });
    }
    const sendRecoveryBtn =(e,param)=>{

        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Envoyé',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            sendRecovery(param).then(async r => {

                if (r.status === 200) {
                    MySwal.fire({
                        html:` <i>${r.data.message}</i>`,
                        icon: 'success'
                    }
                    )
                   // await navigate('/comptes')
                }else {
                    MySwal.fire({
                        html:` <i>${r.data.message}</i>`,
                        icon: 'error'
                    })
                }
            });
        })

    }
    const compareDelegation = (a, b) => {
        console.log(!a && !b)
        if (!a && !b) {
            return 0;
        } else if (!a) {
            return -1;
        } else if (!b) {
            return 1;
        } else {
            const delegationA = a.nom_delegation ? a.nom_delegation : '';
            const delegationB = b.nom_delegation ? b.nom_delegation : '';

            const nameA = delegationA ? delegationA.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase() : '';
            const nameB = delegationB ? delegationB.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase() : '';
            console.log(nameA.localeCompare(nameB))
            return nameA.localeCompare(nameB);
        }



    };






    const onUpdate=(e,param)=>{
        navigate(`/comptes/update/${param._id}`);
        // console.log(param)
    }

    const columns = [
        { field: "first_name", headerName: "Prénom", width: 150 ,renderCell: (params) => {
                return (
                    <>
                        <Link
                            onClick={(e) => onUpdate(e, params.row)}
                            variant="contained"
                        >
                            {params.row.first_name}
                        </Link>
                    </>

                );
            } },
        { field: "last_name", headerName: "Nom", width: 150 },
        { field: "email", headerName: "Email", width: 200 },
        {
            field: 'delegation',
            headerName: 'Délégation',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="rowitem">
                        {params.row.delegation ? params.row.delegation.nom_delegation : ''}
                    </div>
                );
            },
            sortComparator: compareDelegation,
        },
        { field: "role", headerName: "Rôle", width: 150 },
        { field: "is_active", headerName: "Statut", width: 150 ,renderCell: (params) => {
                if (params.row.is_active) {
                    return (
                        <>
                            <Chip label="Activé" color="primary" variant="outlined" />

                        </>

                    );
                }else {
                    return (
                        <>
                            <Chip label="Désactivé" color="error" variant="outlined"/>

                        </>

                    );
                }

            } },
        { field: "updatedAt", headerName: "Date de modification", width: 220,renderCell: (params) => {

                return <div >{convertDate(params.row.createdAt)}</div>;
            }},
        { field: 'Mail', headerName: 'Mot de passe', width: 250, renderCell: (params) => {
                return (
                    <>
                        <Button
                            onClick={(e) => sendRecoveryBtn(e, params.row._id)}
                            variant="contained"
                        >
                            Envoi email
                        </Button>

                    </>

                );
            } }
    ];


    return (
            <div style={{ height: '100%', maxWidth: '100%', margin: 'auto', overflow: 'auto' }}>
            <DataGrid
                rows={dataTable}
                getRowId={(row) => row._id}
                columns={columns}
                hideFooter={true}
                style={{ width: '100%' }}
                // getRowHeight={() => 100}
                autoHeight={true}
               // autoHeight={true}
            />
        </div>
    );
}
