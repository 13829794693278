import Layout from "../../layout/Layout.jsx";
import {Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import DatePicker from "react-datepicker";
import Button from "@mui/material/Button";
import HistogramV2 from "../../components/HistogramV2.jsx";
import PieChartComponent from "../../components/PieChartComponent.jsx";
import HistogramV2Branche from "../../components/HistogramV2Branche.jsx";
import PieChartComponentBranche from "../../components/PieChartComponentBranche.jsx";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute.jsx";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";
import {getDelegation, getStats} from "../../api/Api.jsx";
import TableMetierDistribue from "../../components/TableMetierDistribue.jsx";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import Box from "@mui/material/Box";
registerLocale('fr', fr)
setDefaultLocale('fr');

export default function StatBranche() {
    const [stats,setStats]=useState([]);
    const { isAuthenticated } = useContext(AuthContext);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const SkeletonTable = <LoadingIndicator
    />
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dataFilterByDate,setDataFilterByDate]=useState([])
    const [delegation,setDelegation]=useState([]);
    const [viewsCheckBoxDelegation,setViewsCheckBoxDelegation]=useState(false)
    const [dataFilter,setDataFilter]=useState()
    const [isChecked ,setIsChecked]=useState(false)

    function filterDates(startDate, endDate, data) {
        return data.filter(item => {
            const itemDate = new Date(item.date);
            return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
        });
    }
    const filteredDataByRegion = (selectedDelegations) => {
        console.log("///////////////")
        console.log(selectedDelegations)
        console.log(dataFilterByDate)
        console.log("///////////////")
        return dataFilterByDate.filter(item => selectedDelegations.includes(item.region._id));
    };
    const valideDate = () => {
        setIsChecked(false);

        // Stocker l'état actuel des checkboxes
        const checkboxStates = {};
        selectedDelegations.forEach(id => {
            checkboxStates[id] = true;
        });

        let data = filterDates(startDate.toISOString(), endDate.toISOString(), stats);
        setDataFilterByDate(data);

        getDelegation()
            .then(resp => {
                setDelegation(resp.data);
                setDataFilter(resp.data);

                // Restaurer l'état des checkboxes
                const newSelectedDelegations = resp.data.filter(item => checkboxStates[item.id]);
                setSelectedDelegations(newSelectedDelegations);

                setViewsCheckBoxDelegation(true);
            })
            .catch(err => {
                // Gérer les erreurs ici
            });
    };


    const [selectedDelegations, setSelectedDelegations] = useState([]);
    const handleChangeStartDate = (date) => {
        setStartDate(date);

        // Si la date de fin est antérieure à la date de début, la mettre à jour
        if (endDate < date) {
            setEndDate(date);
        }
    };

    const handleChangeEndDate = (date) => {
        // Si la date de fin est antérieure à la date de début, empêcher la mise à jour
        if (date >= startDate) {
            setEndDate(date);
        }
    };
    const handleChange = (event) => {
        const delegationId = event.target.value;
        let data=[delegationId];
        let data2 = [...selectedDelegations];
        if (selectedDelegations.includes(delegationId)) {
            setSelectedDelegations(selectedDelegations.filter(id => id !== delegationId));
        } else {
            setSelectedDelegations([...selectedDelegations, delegationId]);

        }
        data = [...data, ...selectedDelegations];

        let dd=filteredDataByRegion(data)
        setDataFilter(dd)



    };

    const handleSelectAll = () => {
        setSelectedDelegations(delegation.map(delegation => delegation._id));
        let dd=filteredDataByRegion(delegation.map(delegation => delegation._id))
        setDataFilter(dd)
        setIsChecked(true);
    };

    const handleReset = () => {
        setSelectedDelegations([]);
        setIsChecked(false);
    };

    useEffect(() => {
        getStats()
            .then(resp => {
                setStats(resp.data)
                setDataFilter(resp.data)
                setLoadingStatus(false);
            }).catch(err => {

        })

    }, [])
    useEffect(() => {
        setIsChecked(selectedDelegations.length > 0);
    }, [selectedDelegations]);
    // useEffect(() => {
    //     setDateString(dateRange.map(dateObject => dateObject && dateObject.toISOString()).filter(Boolean));
    //     if (dateStrings && stats) {
    //         console.log(dateStrings)
    //         setFilteredDataByTime(stats.filter(item => dateStrings.includes(item.date)));
    //     }
    // }, [dateRange]);

    const sortedDelegations = [...delegation].sort((a, b) => a.nom_delegation.localeCompare(b.nom_delegation));
    const numCols = 3; // nombre de colonnes que vous voulez
    const delegationsPerCol = Math.ceil(sortedDelegations.length / numCols); // nombre de délégations par colonne

// diviser les délégations en sous-tableaux pour chaque colonne
    const delegationsInCols = [];
    for (let i = 0; i < sortedDelegations.length; i += delegationsPerCol) {
        delegationsInCols.push(sortedDelegations.slice(i, i + delegationsPerCol));
    }
    const frFR = {
        // Root
        noRowsLabel: 'Pas de résultats',
        noResultsOverlayLabel: 'Aucun résultat.',

        // Density selector toolbar button text
        toolbarDensity: 'Densité',
        toolbarDensityLabel: 'Densité',
        toolbarDensityCompact: 'Compacte',
        toolbarDensityStandard: 'Standard',
        toolbarDensityComfortable: 'Confortable',

        // Columns selector toolbar button text
        toolbarColumns: 'Colonnes',
        toolbarColumnsLabel: 'Choisir les colonnes',

        // Filters toolbar button text
        toolbarFilters: 'Filtres',
        toolbarFiltersLabel: 'Afficher les filtres',
        toolbarFiltersTooltipHide: 'Cacher les filtres',
        toolbarFiltersTooltipShow: 'Afficher les filtres',
        toolbarFiltersTooltipActive: (count) =>
            count > 1 ? `${count} filtres actifs` : `${count} filtre actif`,

        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: 'Rechercher…',
        toolbarQuickFilterLabel: 'Recherche',
        toolbarQuickFilterDeleteIconLabel: 'Supprimer',

        // Export selector toolbar button text
        toolbarExport: 'Exporter',
        toolbarExportLabel: 'Exporter',
        toolbarExportCSV: 'Télécharger en CSV',
        toolbarExportPrint: 'Imprimer',
        toolbarExportExcel: 'Télécharger pour Excel',

        // Columns panel text
        columnsPanelTextFieldLabel: 'Chercher une colonne',
        columnsPanelTextFieldPlaceholder: 'Titre de la colonne',
        columnsPanelDragIconLabel: 'Réorganiser la colonne',
        columnsPanelShowAllButton: 'Tout afficher',
        columnsPanelHideAllButton: 'Tout cacher',

        // Filter panel text
        filterPanelAddFilter: 'Ajouter un filtre',
        filterPanelRemoveAll: 'Tout supprimer',
        filterPanelDeleteIconLabel: 'Supprimer',
        filterPanelLogicOperator: 'Opérateur logique',
        filterPanelOperator: 'Opérateur',
        filterPanelOperatorAnd: 'Et',
        filterPanelOperatorOr: 'Ou',
        filterPanelColumns: 'Colonne',
        filterPanelInputLabel: 'Valeur',
        filterPanelInputPlaceholder: 'Filtrer la valeur',

        // Filter operators text
        filterOperatorContains: 'contient',
        filterOperatorEquals: 'est égal à',
        filterOperatorStartsWith: 'commence par',
        filterOperatorEndsWith: 'se termine par',
        filterOperatorIs: 'est',
        filterOperatorNot: "n'est pas",
        filterOperatorAfter: 'postérieur',
        filterOperatorOnOrAfter: 'égal ou postérieur',
        filterOperatorBefore: 'antérieur',
        filterOperatorOnOrBefore: 'égal ou antérieur',
        filterOperatorIsEmpty: 'est vide',
        filterOperatorIsNotEmpty: "n'est pas vide",
        filterOperatorIsAnyOf: 'fait partie de',
        'filterOperator=': '=',
        'filterOperator!=': '!=',
        'filterOperator>': '>',
        'filterOperator>=': '>=',
        'filterOperator<': '<',
        'filterOperator<=': '<=',

        // Header filter operators text
        headerFilterOperatorContains: 'Contient',
        headerFilterOperatorEquals: 'Est égal à',
        headerFilterOperatorStartsWith: 'Commence par',
        headerFilterOperatorEndsWith: 'Se termine par',
        headerFilterOperatorIs: 'Est',
        headerFilterOperatorNot: "N'est pas",
        headerFilterOperatorAfter: 'Postérieur',
        headerFilterOperatorOnOrAfter: 'Égal ou postérieur',
        headerFilterOperatorBefore: 'Antérieur',
        headerFilterOperatorOnOrBefore: 'Égal ou antérieur',
        headerFilterOperatorIsEmpty: 'Est vide',
        headerFilterOperatorIsNotEmpty: "N'est pas vide",
        headerFilterOperatorIsAnyOf: 'Fait partie de',
        'headerFilterOperator=': 'Est égal à',
        'headerFilterOperator!=': "N'est pas égal à",
        'headerFilterOperator>': 'Est supérieur à',
        'headerFilterOperator>=': 'Est supérieur ou égal à',
        'headerFilterOperator<': 'Est inférieur à',
        'headerFilterOperator<=': 'Est inférieur ou égal à',

        // Filter values text
        filterValueAny: 'tous',
        filterValueTrue: 'vrai',
        filterValueFalse: 'faux',

        // Column menu text
        columnMenuLabel: 'Menu',
        columnMenuShowColumns: 'Afficher les colonnes',
        columnMenuManageColumns: 'Gérer les colonnes',
        columnMenuFilter: 'Filtrer',
        columnMenuHideColumn: 'Cacher',
        columnMenuUnsort: 'Annuler le tri',
        columnMenuSortAsc: 'Tri ascendant',
        columnMenuSortDesc: 'Tri descendant',

        // Column header text
        columnHeaderFiltersTooltipActive: (count) =>
            count > 1 ? `${count} filtres actifs` : `${count} filtre actif`,
        columnHeaderFiltersLabel: 'Afficher les filtres',
        columnHeaderSortIconLabel: 'Trier',

        // Rows selected footer text
        footerRowSelected: (count) =>
            count > 1
                ? `${count.toLocaleString()} lignes sélectionnées`
                : `${count.toLocaleString()} ligne sélectionnée`,

        // Total row amount footer text
        footerTotalRows: 'Total de lignes :',

        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,

        // Checkbox selection text
        checkboxSelectionHeaderName: 'Sélection',
        checkboxSelectionSelectAllRows: 'Sélectionner toutes les lignes',
        checkboxSelectionUnselectAllRows: 'Désélectionner toutes les lignes',
        checkboxSelectionSelectRow: 'Sélectionner la ligne',
        checkboxSelectionUnselectRow: 'Désélectionner la ligne',

        // Boolean cell text
        booleanCellTrueLabel: 'vrai',
        booleanCellFalseLabel: 'faux',

        // Actions cell more text
        actionsCellMore: 'Plus',

        // Column pinning text
        pinToLeft: 'Épingler à gauche',
        pinToRight: 'Épingler à droite',
        unpin: 'Désépingler',

        // Tree Data
        treeDataGroupingHeaderName: 'Groupe',
        treeDataExpand: 'afficher les enfants',
        treeDataCollapse: 'masquer les enfants',

        // Grouping columns
        groupingColumnHeaderName: 'Groupe',
        groupColumn: (name) => `Grouper par ${name}`,
        unGroupColumn: (name) => `Arrêter de grouper par ${name}`,

        // Master/detail
        detailPanelToggle: 'Afficher/masquer les détails',
        expandDetailPanel: 'Afficher',
        collapseDetailPanel: 'Masquer',

        // Row reordering text
        rowReorderingHeaderName: 'Positionnement des lignes',

        // Aggregation
        aggregationMenuItemHeader: 'Agrégation',
        aggregationFunctionLabelSum: 'Somme',
        aggregationFunctionLabelAvg: 'Moyenne',
        aggregationFunctionLabelMin: 'Minimum',
        aggregationFunctionLabelMax: 'Maximum',
        aggregationFunctionLabelSize: "Nombre d'éléments",
    }


    console.log("**********delegation************");
    console.log(selectedDelegations);
    console.log("**********************");

    return (
        <>
            <Box width={1}>
            <Grid container  justify="center" alignItems="center" className="gridCustom2">
                <Grid item xs={12} style={{textAlign:"center"}}>
                    <Typography variant="h6" style={{paddingBottom:'20px'}}>Définition de la période</Typography>
                </Grid>
                <Grid item  style={{paddingTop:"10px",paddingRight:"20px"}}>
                    <Typography>Date de début de période</Typography>
                    <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={handleChangeStartDate}
                        dateFormat="dd/MM/yyyy"
                        locale="fr"
                    />
                </Grid>
                <Grid item  style={{paddingTop:"10px",paddingRight:"20px"}}>
                    <Typography>Date de fin de période</Typography>
                    <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={handleChangeEndDate}
                        dateFormat="dd/MM/yyyy"
                        minDate={startDate}
                        locale="fr"// Empêcher la sélection de dates antérieures à la date de début
                    />
                </Grid>
                <Grid item>
                    <Button className="with-after_icon-akto btn-primary-filled" onClick={() => valideDate()}>Valider</Button>
                </Grid>
            </Grid>
            </Box>
            {/*<Grid container spacing={5} justify="center" alignItems="center" className="gridCustom2" style={{paddingTop:"20px"}}>*/}

            {/*</Grid>*/}
            { viewsCheckBoxDelegation &&
                <Grid container spacing={5} justify="center" alignItems="center" className="gridCustom2" style={{marginTop:"0px",paddingBottom:"50px"}}>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <Typography variant="h6" style={{paddingBottom:'20px'}}>Sélection des délégations</Typography>
                    </Grid>

                    <Grid container justify="center" style={{paddingTop:"10px", padding: '0 16px',width: '50%',position:'relative',left:'60px'}}>
                        {delegationsInCols.map((delegationsInCol, colIndex) => (
                            <Grid item xs={12} sm={4} key={colIndex} style={{ width: '33.33%' }}>
                                {delegationsInCol.map((delegation) => (
                                    <Grid item xs={12} key={delegation._id}>
                                        <FormControlLabel
                                            control={<Checkbox checked={selectedDelegations.includes(delegation._id)} onChange={handleChange} value={delegation._id} />}
                                            label={delegation.nom_delegation}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>








                    <Grid item xs={12} style={{textAlign:"center",paddingTop:"0px"}}>
                        <a onClick={selectedDelegations.length === delegation.length ? handleReset : handleSelectAll} style={{textDecoration:'underline'}}>
                            {selectedDelegations.length === delegation.length ? 'Réinitialiser la sélection' : 'Sélectionner toutes les régions'}
                        </a>
                    </Grid>
                </Grid>
            }
            { isChecked &&
                <Box width={1}>
                <Grid container spacing={5} justify="center" alignItems="center" direction="column" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <Grid item xs={12} sm={6} md={4} className="gridCustomStat" style={{width:'100%'}}>
                        <Grid container spacing={5} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h6">Nombre de fiches métiers distribuées par branche</Typography>
                            </Grid>
                            <Grid item xs={4} sm={6} md={12} >
                                <HistogramV2 data={dataFilter} />
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} className="gridCustomStat">
                        <Grid container spacing={5} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h6">Répartition par branche des fiches métiers distribuées</Typography>
                            </Grid>
                            <Grid item xs={12} className="center-content">
                                <PieChartComponent data={dataFilter} />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid container xs={12} sm={12} md={12} className="gridCustomStat">
                        <Grid container spacing={5} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{paddingTop:'40px'}}>Nombre de fiches métiers distribuées</Typography>
                            </Grid>
                            <Grid container item xs={12} sm={12} md={12} justify="center" style={{marginTop:'16px',paddingTop:'16px',paddingLeft:'100px'}}>



                                    <TableMetierDistribue startDate={startDate.toISOString()} endDate={endDate.toISOString()} selectedDelegations={selectedDelegations}/>
                                    {/*<HistogramV2Branche data={dataFilter} />*/}

                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
                </Box>
            }

                </>

    )
}