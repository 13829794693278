
import {
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    NativeSelect,
    Select, Stack, styled,
    Switch, TextareaAutosize, TextField, Typography
} from '@mui/material';
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import {
    createCategories,
    createDelegation, createTypologie, deleteTypologie,
    getOneCategories,
    getOneDelegation, getOneTypologie,
    updateCategories,
    updateDelegation, updateTypologie
} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import AlertDialog from "../dialog";
import Skeleton from "@mui/material/Skeleton";
import MySwal from "sweetalert2";
import {AuthContext} from "../ProtectedRoute/ProtectedRoute";
import Layout from "../../layout/Layout";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

function BootstrapInput() {
    return null;
}

export default function FormulaireTypologie() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const { register, control,formState: { errors }, handleSubmit } = useForm();
    const [category, setCategory] = useState({});
    const [dialogData, setDialogData] = useState({});
    const [test,setTest]=useState();
    const { id } = useParams();
    const [titre ,setTitre]=useState("");
    const [message,setMessage]=useState("");
    const [active, setActive]=useState(true);
    const { isAuthenticated } = useContext(AuthContext);
    const [textSup,setTextSup]=useState("")
    const returnPreviewsPage = () => {
        navigate('/profils')
    };
    useEffect(() => {

        // Fetch existing category data using the id passed as prop
        if(id){
            getOneTypologie(id).then(r => {
                if (r.status === 200) {

                    setCategory(r.data)
                    setTitre(r.data.titre);
                    setMessage(r.data.mailTemplate);
                    setActive(r.data.is_active);
                    setTextSup(r.data.textSup)
                    setLoadingStatus(false);
                }
            });
        }else {
            setLoadingStatus(false);
        }

    }, [id]);

    const onSubmit = (data,e) => {


        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Valider',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if(id){
                    updateTypologie(id,data,message,textSup).then(r => {
                        if(r.status === 200){
                            MySwal.fire({
                                    html:` <i>${r.data.message}</i>`,
                                    icon: 'success',
                                confirmButtonColor: '#00a79b',
                                }
                            ).then(()=>{
                                navigate('/profils')
                            })

                        }else {
                            MySwal.fire({
                                html:` <i>${r.data.message}</i>`,
                                icon: 'error',
                                confirmButtonColor: '#00a79b',
                            })
                        }
                        setOpen(false);
                    });
                }else {

                    createTypologie(data,message,textSup).then(r => {
                        if(r.status === 200){
                            MySwal.fire({
                                    html:` <i>${r.data.message}</i>`,
                                    icon: 'success',
                                confirmButtonColor: '#00a79b',
                                }
                            ).then(()=>{
                                navigate('/profils')
                            })

                        }else {
                            MySwal.fire({
                                html:` <i>${r.data.message}</i>`,
                                icon: 'error',
                                confirmButtonColor: '#00a79b',
                            })
                        }
                        setOpen(false);
                    });
                }
            }
        })




    };

    const SkeletonTable = <Skeleton sx={{bgcolor: 'grey.900'}}
                                    variant="rectangular"
                                    width={210}
                                    height={118}
    />
    const  onDeleted =(e,param)=>{
        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Supprimer',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteTypologie(id).then(async r => {
                    if (r.status === 200) {
                        await navigate('/profils')
                    }
                });
            }

        })

    }

    return(
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <Layout isAuthenticated={isAuthenticated}>
                    <Grid item xs={12} >
                        <Typography variant="h1" className="containerTitreForm">
                            {id ? "Modification d'un profils de demandeur" : "Ajout d'un nouveau profil de demandeur"}
                        </Typography>
                    </Grid>
                        <form  className="borderForm" onSubmit={handleSubmit(onSubmit)}>
                    <Grid  container
                           spacing={0}
                        // direction="column"
                           alignItems="center"
                           justifyContent="center"
                           style={{ minHeight: '50vh'}}
                           rowSpacing={5}
                           columnSpacing={{ xs:4, sm: 4, md: 4 }}
                    >


                            <Grid  container  alignItems="center"
                                   justifyContent="center" rowSpacing={1} columnSpacing={{ xs:12, sm: 12, md: 12  }}>
                                <Grid item xs={12}  alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Controller
                                        name="titre"
                                        control={control}
                                        defaultValue={titre}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TextField
                                                label="Titre"
                                                id="outlined"
                                                name="titre"
                                                className="boxForm"
                                                value={value}
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                        rules={{ required: 'Le titre est obligatoire' }}
                                    />

                                </Grid>
                                <Grid item xs={12} alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Grid item xs={12} alignItems="center"
                                          justifyContent="center" className="boxAjustement">
                                        <InputLabel htmlFor="outlined-adornment-amount" className='labelForm'>Message personnalisé dans l'email</InputLabel>
                                    </Grid>

                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={message}
                                        config={{
                                            wordCount: {
                                                maxCharCount: 100
                                            },
                                            extraKeys: {
                                                'Shift-Enter': 'br'
                                            },
                                            toolbar: [ 'bold', 'italic', '|','fontColor','fontBackgroundColor','link']
                                        }}
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setMessage(data)

                                        } }
                                        onBlur={ ( event, editor ) => {

                                        } }
                                        onFocus={ ( event, editor ) => {

                                        } }

                                    />

                                </Grid>
                                <Grid item xs={12} alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Grid item xs={12} alignItems="center"
                                          justifyContent="center" className="boxAjustement">
                                        <InputLabel htmlFor="outlined-adornment-amount" className='labelForm'>Message personnalisé dans l'email</InputLabel>
                                    </Grid>

                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={textSup}
                                        config={{
                                            wordCount: {
                                                maxCharCount: 100
                                            },
                                            extraKeys: {
                                                'Shift-Enter': 'br'
                                            },
                                            toolbar: [ 'bold', 'italic', '|','fontColor','fontBackgroundColor','link']
                                        }}
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setTextSup(data)

                                        } }
                                        onBlur={ ( event, editor ) => {

                                        } }
                                        onFocus={ ( event, editor ) => {

                                        } }

                                    />

                                </Grid>
                                    {/*<InputLabel htmlFor="outlined-adornment-amount">Signature</InputLabel>*/}
                                    {/*<Controller*/}
                                    {/*    name="mail_template"*/}
                                    {/*    control={control}*/}
                                    {/*    defaultValue={message}*/}
                                    {/*    render={({ field: { onChange, value }, fieldState: { error } }) => (*/}
                                    {/*        <TextField*/}
                                    {/*            id="outlined-multiline-static"*/}
                                    {/*            label="Message personnalisé dans l'email"*/}
                                    {/*            multiline*/}
                                    {/*            rows={4}*/}
                                    {/*            defaultValue=""*/}
                                    {/*            value={value}*/}
                                    {/*            onChange={onChange}*/}
                                    {/*            error={!!error}*/}
                                    {/*            helperText={error ? error.message : null}*/}
                                    {/*            FormHelperTextProps={{*/}
                                    {/*                component: 'div',*/}
                                    {/*            }}*/}
                                    {/*            style={{width:"100%"}}*/}
                                    {/*        />*/}

                                    {/*        // <TextField*/}
                                    {/*        //     label="Rang"*/}
                                    {/*        //     id="outlined"*/}
                                    {/*        //     className="boxForm"*/}
                                    {/*        //     variant="outlined"*/}
                                    {/*        //     type="number"*/}
                                    {/*        //     value={value}*/}
                                    {/*        //     onChange={onChange}*/}
                                    {/*        //     error={!!error}*/}
                                    {/*        //     helperText={error ? error.message : null}*/}
                                    {/*        //     FormHelperTextProps={{*/}
                                    {/*        //         component: 'div',*/}
                                    {/*        //     }}*/}
                                    {/*        // />*/}
                                    {/*    )}*/}
                                    {/*    rules={{ required: 'Le message est obligatoire' }}*/}
                                    {/*/>*/}
                                {/*</Grid>*/}


                                {/*<Grid item xs={12} alignItems="center"*/}
                                {/*      justifyContent="center" className="boxAjustement">*/}
                                {/*    <InputLabel htmlFor="outlined-adornment-amount">Activé la typologie ?</InputLabel>*/}
                                {/*</Grid>*/}

                                {/*<Grid item xs={12} alignItems="center"*/}
                                {/*      justifyContent="center" className="boxAjustement">*/}
                                {/*    <Stack direction="row" spacing={1} alignItems="center"*/}
                                {/*           justifyContent="center">*/}
                                {/*        <Typography>Non</Typography>*/}
                                {/*        <Controller*/}
                                {/*            name="is_active"*/}
                                {/*            control={control}*/}
                                {/*            defaultValue={true}*/}
                                {/*            render={({ field: { onChange, value }, fieldState: { error } }) => (*/}
                                {/*                <AntSwitch*/}

                                {/*                    value={value}*/}
                                {/*                    onChange={onChange}*/}
                                {/*                    className="boxForm"*/}
                                {/*                />*/}
                                {/*            )}*/}
                                {/*        />*/}
                                {/*        <Typography>Oui</Typography>*/}
                                {/*    </Stack>*/}
                                {/*</Grid>*/}
                                <Grid container className="boxAjustement2">
                                    <Grid item xs={12} alignItems="center"
                                          justifyContent="center" className="boxAjustement">
                                        <InputLabel htmlFor="outlined-adornment-amount" className='labelForm'>Active ?</InputLabel>
                                    </Grid>

                                    <Grid item xs={12} alignItems="center"
                                          justifyContent="center" className="boxAjustement">
                                        <Stack direction="row" spacing={1} alignItems="center"
                                               justifyContent="center">
                                            <Typography>Non</Typography>
                                            <Controller
                                                name="is_default_select"
                                                control={control}
                                                defaultValue={true}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <AntSwitch
                                                        checked={value}
                                                        value={value}
                                                        onChange={onChange}
                                                        // className="boxForm"
                                                    />
                                                )}
                                            />
                                            <Typography>Oui</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Button
                                        className="with-after_icon-akto btn-secondary-filled-step2"
                                        onClick={(e) => returnPreviewsPage()}
                                        variant="contained"
                                        style={{marginRight:"20px"}}
                                    >
                                        Retour
                                    </Button>
                                    <Button className="with-after_icon-akto btn-primary-filled-step2" type="submit">Valider</Button>

                                </Grid>
                                { id &&
                                    <Grid item xs={12} alignItems="center"
                                          justifyContent="center" className="boxAjustement">
                                        <Button
                                            className="with-after_icon-akto btn-secondary-filled-step2"
                                            onClick={(e) => onDeleted(e, id)}
                                            variant="contained"
                                        >
                                            Supprimer
                                        </Button>

                                    </Grid>
                                }

                            </Grid>

                    </Grid>
                        </form>
                    </Layout>
                </>
            )}
        </>
    )


}