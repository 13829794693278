import {getCategories} from "../../api/Api";
import {useContext, useEffect, useState} from 'react';
import DataGridCategorie from "./DataGridCategorie";
import Skeleton from '@mui/material/Skeleton';
import {Grid, Typography, Item} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {appThemeAkto} from "../../themes/theme";
import Button from "@mui/material/Button";
import formulaireCat from "../../components/CRUD_categorie/formulaireCat";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";
import Layout from "../../layout/Layout";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";
// import Link  from "react-router-dom";

export default function Categorie() {

    const [dataCategorie, setDataCategorie] = useState();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext)

    const SkeletonTable = <LoadingIndicator
    />


    useEffect(() => {
        getCategories()
            .then(resp => {
                setDataCategorie(resp.data);
                setLoadingStatus(false);
            }).catch(err => {

        })
    }, [])




    return (
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    {/*<Layout isAuthenticated={isAuthenticated}>*/}
                    {/*<Grid container spacing={5} justify="center" alignItems="center">*/}
                    {/*    <Grid item xs={12} className="containerTitre">*/}
                    {/*        <Typography variant="h1">Catégories</Typography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={8}>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={4} className="gridCustom">*/}
                    {/*        <Button className="with-after_icon-akto btn-primary-filled" onClick={() => navigate('/categorie/ajout')}>Ajout d'une categorie</Button>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12}  style={{margin: "auto"}}>*/}
                    {/*        <DataGridCategorie data={{dataCategorie}} />*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    {/*</Layout>*/}
                    <Layout isAuthenticated={isAuthenticated}>
                        <Grid container spacing={5} justify="center" alignItems="center" >
                            <Grid item xs={12} className="containerTitre">
                                <Typography variant="h1">Catégories</Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4} className="gridCustom" style={{paddingBottom:"50px"}}>
                                <Button className="with-after_icon-akto btn-primary-filled" onClick={() => navigate('/categorie/ajout')}>Ajouter une catégorie</Button>
                            </Grid>
                            <Grid container justify="center" alignItems="center" >

                            <Grid item xs={12} justify="center" alignItems="center" style={{paddingBottom:"200px"}}  className={"tabuser"}>
                                <DataGridCategorie data={{dataCategorie}} />
                            </Grid>

                            </Grid>
                            <Grid container spacing={5} justify="center" alignItems="center" style={{paddingTop:"200px"}}>
                            </Grid>
                        </Grid>
                    </Layout>

                </>

            )}
        </>
    );

}
