import {getCategories, getTypologie} from "../../api/Api";
import {useContext, useEffect, useState} from 'react';
import Skeleton from '@mui/material/Skeleton';
import {Grid, Typography, Item} from "@mui/material";

import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import DataGridTypologie from "./DataGridTypologie";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";
import Layout from "../../layout/Layout";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";

export default function Typologies() {

    const [dataTypologie, setDataTypologie] = useState();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext)

    const SkeletonTable = <LoadingIndicator
    />


    useEffect(() => {
        getTypologie()
            .then(resp => {
                setDataTypologie(resp.data);
                setLoadingStatus(false);
                console.log(resp.data)
            }).catch(err => {
            console.log('error from fetching categories', err);
        })
    }, [])



    return (
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <Layout isAuthenticated={isAuthenticated}>
                    <Grid container spacing={5} justify="center" alignItems="center">
                        <Grid item xs={12} className="containerTitre">
                            <Typography variant="h1">Profils de demandeur</Typography>
                        </Grid>
                        <Grid item xs={8}>
                        </Grid>
                        <Grid item xs={4} className="gridCustom" style={{paddingBottom:"50px"}}>
                            <Button className="with-after_icon-akto btn-primary-filled" onClick={() => navigate('/profils/ajout')}>Ajouter un profil</Button>
                        </Grid>
                        <Grid container spacing={5} justify="center" alignItems="center">

                        <Grid item xs={12} justify="center" alignItems="center" style={{paddingBottom:"200px"}}  className={"tabuser"}>
                            <DataGridTypologie data={{ dataTypologie}} />
                        </Grid>

                        </Grid>
                        <Grid container spacing={5} justify="center" alignItems="center" style={{paddingTop:"200px"}}>
                        </Grid>
                    </Grid>
                    </Layout>

                </>

            )}
        </>
    );

}
