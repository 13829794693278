import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {deleteCategories, deleteDelegation, deleteTypologie} from "../../api/Api";
import {Chip, TextField} from "@mui/material";
import Link from "@mui/material/Link";



export default function DataGridTypologie({data}) {


    const dataRecieve = data.dataTypologie;
    let dataTable = dataRecieve;
    const navigate = useNavigate();


    const  onDeleted =(e,param)=>{
        deleteTypologie(param._id).then(async r => {
            if (r.status === 200) {
                await navigate('/profils')
            }
        });
    }
    const onUpdate=(e,param)=>{
        navigate(`/profils/update/${param._id}`);
        // console.log(param)
    }

    const columns = [
        // { field: "_id", headerName: "ID", width: 200 },
        { field: "titre", headerName: "Profil", width: 400 ,renderCell: (params) => {
                return (
                    <>
                        <Link
                            onClick={(e) => onUpdate(e, params.row)}
                            variant="contained"
                        >
                            {params.row.titre}
                        </Link>

                    </>

                );
            } },
        { field: "mailTemplate", headerName: "Message", width: 750 ,renderCell: (params) => {
                const parser = new DOMParser();
                const parsedContent = parser.parseFromString(params.value, 'text/html');
                const textContent = parsedContent.documentElement.textContent;
                return (
                    <>
                        <div style={{ whiteSpace: 'break-spaces'}} >
                            {textContent}
                        </div>


                    </>

                );
            } },
        { field: "textSup", headerName: "textSup", width: 750 ,renderCell: (params) => {
                const parser = new DOMParser();
                const parsedContent = parser.parseFromString(params.value, 'text/html');
                const textContent = parsedContent.documentElement.textContent;
                return (
                    <>
                        <div style={{ whiteSpace: 'break-spaces'}} >
                            {textContent}
                        </div>


                    </>

                );
            } },
        { field: "is_active", headerName: "Statut", width: 150 ,renderCell: (params) => {
                if (params.row.is_active) {
                    return (
                        <>
                            <Chip label="Activé" color="primary" variant="outlined" />

                        </>

                    );
                }else {
                    return (
                        <>
                            <Chip label="Désactivé" color="error" variant="outlined"/>

                        </>

                    );
                }

            } },
        // { field: "createdAt", headerName: "Date de création", width: 220 },
        // { field: 'updatedAt', headerName: 'Modifier', width: 200, renderCell: (params) => {
        //         return (
        //             <>
        //                 <Button
        //                     onClick={(e) => onUpdate(e, params.row)}
        //                     variant="contained"
        //                 >
        //                     Mise à jour
        //                 </Button>
        //
        //             </>
        //
        //         );
        //     } },
        // { field: 'Supprimer', headerName: 'Supprimer', width: 200, renderCell: (params) => {
        //         return (
        //             <>
        //                 <Button
        //                     onClick={(e) => onDeleted(e, params.row)}
        //                     variant="contained"
        //                 >
        //                     Supprimer
        //                 </Button>
        //
        //             </>
        //
        //         );
        //     } }
    ];
    console.log('dataTable', dataTable);

    return (
        <div style={{ height: 'calc(100vh - 50px)', maxWidth: '100%', margin: 'auto', overflow: 'auto' }}>
            <DataGrid

                rows={dataTable}
                getRowId={(row) => row._id}
                columns={columns}
                hideFooter={true}
                style={{ width: '100%', tableLayout: 'fixed', height: '100%' }}
                 getRowHeight={() => 100}
                autoHeight={true}
            />
        </div>
    );
}
