
import {
    FormControl,
    FormHelperText,
    Grid,
    Input, InputAdornment,
    InputLabel,
    MenuItem,
    NativeSelect, OutlinedInput,
    Select, Stack, styled,
    Switch, TextField, Typography
} from '@mui/material';
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import {
    createCategories,
    createDelegation, createUser, deleteCategories, deleteUser, getDelegation,
    getOneCategories,
    getOneDelegation, getOneUsers,
    updateCategories,
    updateDelegation, updateUser
} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import AlertDialog from "../dialog";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import MySwal from "sweetalert2";
import {AuthContext} from "../ProtectedRoute/ProtectedRoute";
import Layout from "../../layout/Layout";
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

function BootstrapInput() {
    return null;
}

export default function FormulaireUser() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const { register, control,formState: { errors }, handleSubmit } = useForm();
    const [users, setUser] = useState({});
    const [dialogData, setDialogData] = useState({});
    const [test,setTest]=useState();
    const { id } = useParams();
    const [firstName ,setFirstName]=useState("");
    const [lastName ,setLastName]=useState("");
    const [email ,setEmail]=useState("");
    const [delegation ,setDelegation]=useState("");
    const [password,setPassword]=useState("");
    const [allDelegation,setAllDelegation]=useState([]);
    const [allrole ,setAllRole]=useState(["admin","conseiller"]);
    const [role,setRole]=useState("");
    const [active, setActive]=useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const { isAuthenticated } = useContext(AuthContext)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
     //   event.preventDefault();
    };

    useEffect(() => {


        // Fetch existing category data using the id passed as prop
        if(id){
            getOneUsers(id).then(r => {
                if (r.status === 200) {
                    setFirstName(r.data.first_name);
                    setLastName(r.data.last_name)
                    setEmail(r.data.email)

                    if(r.data.delegation){
                        setDelegation(r.data.delegation._id)
                        getDelegation().then(r => {
                            if (r.status === 200) {

                                setAllDelegation(r.data)
                                setLoadingStatus(false);
                            }
                        });
                    }else {

                        getDelegation().then(r => {
                            if (r.status === 200) {


                                setAllDelegation(r.data)
                                setLoadingStatus(false);
                            }
                        });
                    }

                    setRole(r.data.role)
                    setActive(r.data.is_active);


                }
            });
        }else {
            getDelegation().then(r => {
                if (r.status === 200) {


                    setAllDelegation(r.data)
                    setLoadingStatus(false);
                }
            });
        }

    }, [id]);

    const onSubmit = (data,e) => {


        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Valider',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if(id){
                    updateUser(id,data).then(r => {

                        if(r.status === 200){
                            MySwal.fire({
                                    html:` <i>${r.data.message}</i>`,
                                    icon: 'success',
                                confirmButtonColor: '#00a79b',
                                }
                            ).then(()=>{
                                navigate('/comptes')
                            })

                        }else {
                            MySwal.fire({
                                html:` <i>Le compte conseiller/admin existe déja</i>`,
                                icon: 'error',
                                confirmButtonColor: '#00a79b',
                            })
                        }
                        setOpen(false);
                    });
                }else {

                    createUser(data).then(r => {
                        if(r.status === 200){
                            MySwal.fire({
                                    html:` <i>${r.data.message}</i>`,
                                    icon: 'success',
                                confirmButtonColor: '#00a79b',
                                }
                            ).then(()=>{
                                navigate('/comptes')
                            })
                        }else {
                            MySwal.fire({
                                html:` <i>${r.data.message}</i>`,
                                icon: 'error',
                                confirmButtonColor: '#00a79b',
                            })
                        }
                        setOpen(false);
                    });
                }
            }
        })




    };
    const returnPreviewsPage = () => {
        navigate('/comptes')
    };
    const  onDeleted =(e,param)=>{

        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Supprimer',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser(id).then(async r => {
                    if (r.status === 200) {
                        MySwal.fire({
                                html:` <i>${r.data.message}</i>`,
                                icon: 'success',
                            confirmButtonColor: '#00a79b',
                            }
                        ).then(async () => {
                            await navigate('/comptes')
                        })

                    }else {
                        MySwal.fire({
                            html:` <i>${r.data.message}</i>`,
                            icon: 'error',
                            confirmButtonColor: '#00a79b',
                        })
                    }
                });
            }

        })

    }

    const SkeletonTable = <Skeleton sx={{bgcolor: 'grey.900'}}
                                    variant="rectangular"
                                    width={210}
                                    height={118}
    />

    return(
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <Layout isAuthenticated={isAuthenticated}>
                    <Grid item xs={12} >
                        <Typography variant="h1" className="containerTitreForm">
                            {id ? "Modification d'un utilisateur" : "Ajout d'un utilisateur"}
                        </Typography>
                    </Grid>

                    <Grid  container
                           spacing={0}
                        // direction="column"
                           alignItems="center"
                           justifyContent="center"
                           style={{ minHeight: '50vh'}}
                           rowSpacing={5}
                           columnSpacing={{ xs:4, sm: 4, md: 4 }}
                    >

                        <form  className="borderForm" onSubmit={handleSubmit(onSubmit)}>
                            <Grid  container  alignItems="center"
                                   justifyContent="center" rowSpacing={1} columnSpacing={{ xs:12, sm: 12, md: 12  }}>
                                <Grid item xs={12}  alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Controller
                                        name="last_name"
                                        control={control}
                                        defaultValue={lastName}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TextField
                                                label="Nom"
                                                id="outlined"
                                                name="titre"
                                                className="boxForm"
                                                value={value}
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                        rules={{ required: 'Le nom est obligatoire' }}
                                    />

                                </Grid>
                                <Grid item xs={12}  alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Controller
                                        name="first_name"
                                        control={control}
                                        defaultValue={firstName}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TextField
                                                label="Prénom"
                                                id="outlined"
                                                name="titre"
                                                className="boxForm"
                                                value={value}
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                        rules={{ required: 'Le prénom est obligatoire' }}
                                    />
                                </Grid>
                                <Grid item xs={12}  alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue={email}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TextField
                                                label="Email"
                                                id="outlined"
                                                name="titre"
                                                className="boxForm"
                                                value={value}
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                        rules={{ required: "L'email est obligatoire" }}
                                    />
                                </Grid>


                                <Grid item xs={12}  alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <InputLabel id="demo-select-small">Rôle</InputLabel>
                                    <Controller
                                        name="role"
                                        control={control}
                                        defaultValue={role}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Select
                                                id="outlined"
                                                className="boxForm"
                                                variant="outlined"
                                                value={value}
                                                label="Role"
                                                onChange={(event) => {
                                                    onChange(event);
                                                    setRole(event.target.value);
                                                }}
                                                style={{minWidth:270}}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                                FormHelperTextProps={{
                                                    component: 'div',
                                                }}
                                            >
                                                {allrole.map((option, index) => {


                                                        return (
                                                            <MenuItem key={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        )


                                                    }
                                                    )}
                                            </Select>
                                        )}
                                        rules={{ required: "L'email est obligatoire" }}
                                    />
                                </Grid>
                                {/*{role === "conseiller" && (*/}
                                    <Grid item xs={12} alignItems="center" justifyContent="center" className="boxAjustement">
                                        <InputLabel>Nom de la délégation</InputLabel>
                                        <Controller
                                            name="delegation"
                                            control={control}
                                            defaultValue={delegation}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <Select
                                                    value={value}
                                                    label="Nom de la délégation"
                                                    className="boxForm"
                                                    onChange={onChange}
                                                    style={{ minWidth: 270 }}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    FormHelperTextProps={{
                                                        component: 'div',
                                                    }}
                                                >
                                                    {allDelegation.map((option, index) => {

                                                        if(option.is_active){
                                                            return (
                                                                <MenuItem key={index} value={option._id}>
                                                                    {option.nom_delegation}
                                                                </MenuItem>
                                                            )
                                                        }



                                                        }
                                                    )}
                                                </Select>
                                            )}
                                            rules={{ required: "L'email est obligatoire" }}
                                        />
                                    </Grid>
                                {/*)}*/}

                                {/*<Grid item xs={12}  alignItems="center"*/}
                                {/*      justifyContent="center" className="boxAjustement">*/}
                                {/*    <InputLabel>Nom de la délégation</InputLabel>*/}
                                {/*    <Controller*/}
                                {/*        name="delegation"*/}
                                {/*        control={control}*/}
                                {/*        defaultValue={delegation}*/}
                                {/*        render={({ field: { onChange, value }, fieldState: { error } }) => (*/}
                                {/*            <Select*/}
                                {/*                value={value}*/}
                                {/*                label="Nom de la délégation"*/}
                                {/*                className="boxForm"*/}
                                {/*                onChange={onChange}*/}
                                {/*                style={{minWidth:270}}*/}
                                {/*                error={!!error}*/}
                                {/*                helperText={error ? error.message : null}*/}
                                {/*                FormHelperTextProps={{*/}
                                {/*                    component: 'div',*/}
                                {/*                }}*/}
                                {/*            >*/}
                                {/*                {allDelegation.map((option, index) => (*/}
                                {/*                    <MenuItem key={index} value={option._id}>*/}
                                {/*                        {option.nom_delegation}*/}
                                {/*                    </MenuItem>*/}
                                {/*                ))}*/}
                                {/*            </Select>*/}
                                {/*        )}*/}
                                {/*        rules={{ required: "L'email est obligatoire" }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}


                                <Grid container className="boxAjustement2">
                                    <Grid item xs={12} alignItems="center"
                                          justifyContent="center" className="boxAjustement">
                                        <InputLabel htmlFor="outlined-adornment-amount" className='labelForm'>Activer le compte ?</InputLabel>
                                    </Grid>

                                    <Grid item xs={12} alignItems="center"
                                          justifyContent="center" className="boxAjustement">
                                        <Stack direction="row" spacing={1} alignItems="center"
                                               justifyContent="center">
                                            <Typography>Non</Typography>
                                            <Controller
                                                name="is_active"
                                                control={control}
                                                defaultValue={active}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <AntSwitch
                                                        checked={value}
                                                        value={value}
                                                        onChange={onChange}
                                                        // className="boxForm"
                                                    />
                                                )}
                                            />
                                            <Typography>Oui</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Button
                                        className="with-after_icon-akto btn-secondary-filled-step2"
                                        onClick={(e) => returnPreviewsPage()}
                                        variant="contained"
                                        style={{marginRight:"20px"}}
                                    >
                                        Retour
                                    </Button>
                                    <Button className="with-after_icon-akto btn-primary-filled-step2" type="submit">Valider</Button>


                                </Grid>
                                { id &&
                                    <Grid item xs={12} alignItems="center"
                                          justifyContent="center" className="boxAjustement">
                                        <Button
                                            className="with-after_icon-akto btn-secondary-filled-step2"
                                            onClick={(e) => onDeleted(e, id)}
                                            variant="contained"
                                        >
                                            Supprimer
                                        </Button>

                                    </Grid>
                                }



                            </Grid>
                        </form>
                    </Grid>
                    </Layout>
                </>
            )}
        </>
    )


}
