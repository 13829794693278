import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import MenuItem from '@mui/material/MenuItem';
import {useEffect, useState} from 'react';
import useAuth from "../../security/auth";
import {normalizeString} from "../../function/function";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {getDelegation, getOneUsers} from "../../api/Api.jsx";
import Skeleton from "@mui/material/Skeleton";

const pages = ['Documents', 'Catégories', 'Profils', 'Délégations', 'Statistiques', 'Comptes'];

function NavbarAuth() {

    const [userInfo, setUserInfo] = useState();
    const [pages, setPages] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const {login, logout, user} = useAuth();
    const navigate = useNavigate();
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);

    };

    const handleCloseNavMenu = (page) => {
        console.log(page)
        setAnchorElNav(null);
    };
    const changePage = (e, page) => {
        setAnchorElNav(e.currentTarget);
        let currentPage = normalizeString(page);
        navigate(`/${currentPage}`)
        console.log(currentPage)
    }

    useEffect(() => {
        if(localStorage.getItem('token')){
            let data=jwtDecode(localStorage.getItem('token'))
            setUserInfo(jwtDecode(localStorage.getItem('token')))


            if(data.role =="admin" && data.delegation){
                setPages(['Délégations','Statistiques','Comptes']);
                setLoadingStatus(false);
            }
            else if (data.delegation) {
                setPages(['Délégations', 'Comptes']);
                setLoadingStatus(false);
            } else {
                setPages(['Documents', 'Catégories', 'Profils', 'Délégations', 'Statistiques', 'Comptes']);
                setLoadingStatus(false);

            }
        }


    }, []);


    const handleLogout = () => {
        logout().then(r => console.log("Déconnexion : LocalStorage réinitialisé."))

    };
    const SkeletonTable = <Skeleton sx={{bgcolor: 'grey.900'}}
                                    variant="rectangular"
                                    width={210}
                                    height={118}
    />

    return (
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <AppBar position="static" color="navbar">
                        <Container maxWidth="xl">
                            <Toolbar disableGutters>
                                <LogoHeader/>
                                <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: {xs: "block", md: "none"},
                                        }}
                                    >
                                        {pages.map((page) => (
                                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                                                <Typography textAlign="center" className="navbar">{page}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                                <Box sx={{flexGrow: 1, display: {xs: "none", md: "flex"}}}>
                                    {pages.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={e => {
                                                changePage(e, page)
                                            }}
                                            sx={{my: 2, color: "#1d4851", display: "block"}}
                                        >
                                            <Typography textAlign="center" className="navbar">{page}</Typography>
                                        </Button>
                                    ))}
                                </Box>

                                <Button className="with-after_icon-akto btn-primary-filled"
                                        onClick={handleLogout}>Déconnexion</Button>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </>
            )}
        </>
    )


}
                export default NavbarAuth;
