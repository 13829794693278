import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

// Your colors array
const colors = [
    "#49F0B1",
    "#36C3A8",
    "#0048BE",
    "#001B9C",
    "#7F45C4",
    "#592B8F",
    "#FF708F",
    "#C93857",
    "#FFA807",
    "#FF6707"
];


function PieChartProfilsEnvoieMail({data}) {
    if (data.ficheParTypologie.length === 0) {
        return <p>Aucun résultat.</p>
    }
    const sortedData = [...data.ficheParTypologie].sort((a, b) => a.typologie.localeCompare(b.typologie));

    return (
        <div>
            <h2>Nombre total d’e-mails : {data.totalFiches}</h2>
            <PieChart width={1600} height={600}>
                <Pie
                    dataKey="count" // Change from "ficheParTypologie" to "count"
                    isAnimationActive={false}
                    data={sortedData}
                    cx="50%"
                    cy="50%"
                    innerRadius={170}
                    outerRadius={250}
                    fill="#8884d8"
                    label={(entry) => entry.typologie}
                    nameKey="typologie"
                    startAngle={90}
                    endAngle={-270}
                >
                    {
                        sortedData.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
                    }
                </Pie>
                <Tooltip
                    formatter={(value) => `${value}`}
                />

            </PieChart>
        </div>
    );
}



export default PieChartProfilsEnvoieMail;
