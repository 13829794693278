
import {
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    NativeSelect,
    Select, Stack, styled,
    Switch, TextField, Typography
} from '@mui/material';
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import {
    createCategories,
    createDelegation, deleteDelegation,
    getOneCategories,
    getOneDelegation,
    updateCategories,
    updateDelegation
} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import AlertDialog from "../dialog";
import Skeleton from "@mui/material/Skeleton";
import MySwal from "sweetalert2";
import {AuthContext} from "../ProtectedRoute/ProtectedRoute";
import Layout from "../../layout/Layout";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyDropzone from "../MyDropzone";
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

function BootstrapInput() {
    return null;
}

export default function FormulaireDelegations() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const { register, control,formState: { errors }, handleSubmit } = useForm();
    const [formData, setFormData] = useState({});
    const [delegations, setDelegations] = useState({});
    const [dialogData, setDialogData] = useState({});
    const [test,setTest]=useState();
    const [link,setLink]=useState("");
    const [nomDelegation, setNomDelegation]=useState("");
    const [active, setActive]=useState(true);
    const [salutation,setSalutation]=useState();
    const [signature,setSignature]=useState()
    const { id } = useParams();
    const { isAuthenticated } = useContext(AuthContext);
    const [existingImage,setExistingImage]=useState("");
    const returnPreviewsPage = () => {
        navigate('/delegations')
    };
    useEffect(() => {

        // Fetch existing category data using the id passed as prop
        if(id){
            getOneDelegation(id).then(r => {

                if (r.status === 200) {
                    setDelegations(r.data);
                    setNomDelegation(r.data.nom_delegation)
                    setSignature(r.data.signature_delegation)
                    setSalutation(r.data.salutation)
                    setActive(r.data.is_active)
                    setLink(r.data.link_image)
                    setExistingImage(r.data.imageData)
                    setLoadingStatus(false);
                }
            });
        }else {
            setLoadingStatus(false);
        }

    }, [id]);
    const handleDropzoneData = (data) => {
        setFormData({
            ...formData,
            ...data,
            salutation,
            signature
        });
    };
    const onSubmit = (data,e) => {

        Object.assign(data,formData)


        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Valider',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if(id){
                    updateDelegation(id,data,formData,salutation,signature,link).then(r => {

                        if(r.status === 200){
                            MySwal.fire({
                                html:` <i>${r.data.message}</i>`,
                                icon: 'success',
                                confirmButtonColor: '#00a79b',
                            }).then(()=>{
                                navigate('/delegations')
                            })

                        }else {
                            MySwal.fire({
                                html:` <i>${r.data.message}</i>`,
                                icon: 'error',
                                confirmButtonColor: '#00a79b',
                            })
                        }
                        setOpen(false);
                    });
                }else {

                    createDelegation(data,formData,salutation,signature,link).then(r => {

                        if(r.status === 200){
                            MySwal.fire({
                                    html:` <i>${r.data.message}</i>`,
                                    icon: 'success',
                                confirmButtonColor: '#00a79b',
                                }
                            ).then(()=>{
                                navigate('/delegations')
                            })

                        }
                        setOpen(false);
                    });
                }
            }
        })
            //console.log(test);



    };

    const SkeletonTable = <Skeleton sx={{bgcolor: 'grey.900'}}
                                    variant="rectangular"
                                    width={210}
                                    height={118}
    />
    const  onDeleted =(e,param)=>{
        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Supprimer',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDelegation(param).then(async r => {

                    if (r.status === 200) {
                        MySwal.fire({
                                html:` <i>${r.data.message}</i>`,
                                icon: 'success',
                            confirmButtonColor: '#00a79b',
                            }
                        ).then(async () => {
                            await navigate('/delegations')
                        })

                    }else {
                        MySwal.fire({
                            html:` <i>${r.data.message}</i>`,
                            icon: 'error',
                            confirmButtonColor: '#00a79b',
                        })
                    }
                });
            }

        })

    }

    return(
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <Layout isAuthenticated={isAuthenticated}>
            <Grid item xs={12} >
                <Typography variant="h1" className="containerTitreForm">
                    {id ? "Modification de la délégation" : "Ajout d'une nouvelle délégation"}
                </Typography>
            </Grid>

            <Grid  container
                   spacing={0}
                   // direction="column"
                   alignItems="center"
                   justifyContent="center"
                   style={{ minHeight: '50vh'}}
                   rowSpacing={5}
                   columnSpacing={{ xs:4, sm: 4, md: 4 }}
            >

                <form  className="borderForm" onSubmit={handleSubmit(onSubmit)}>
                    <Grid  container  alignItems="center"
                           justifyContent="center" rowSpacing={1} columnSpacing={{ xs:12, sm: 12, md: 12  }}>
                    <Grid item xs={12}  alignItems="center"
                          justifyContent="center" className="boxAjustement">
                        <Controller
                            name="nomDelegation"
                            control={control}
                            defaultValue={nomDelegation}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label="Nom de la délégation"
                                    id="outlined"
                                    name="nomDelegation"
                                    className="boxForm"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{ required: 'Le nom de délégations est obligatoire' }}
                        />

                    </Grid>
                        <Grid container className="boxAjustement2">
                            <Grid item xs={12} alignItems="center"
                                  justifyContent="center" className="boxAjustement">
                                <InputLabel htmlFor="outlined-adornment-amount" className='labelForm'>Salutation</InputLabel>
                            </Grid>
                            <Grid item xs={12} alignItems="center"
                                  justifyContent="center" className="boxAjustement">
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data={salutation}
                                    config={{
                                        wordCount: {
                                            maxCharCount: 100
                                        },
                                        extraKeys: {
                                            'Enter': 'br'
                                        },
                                        toolbar: [ 'bold', 'italic', '|','fontColor','fontBackgroundColor']
                                    }}
                                    onReady={ editor => {
                                        // You can store the "editor" and use when it is needed.

                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setSalutation(data)


                                    } }
                                    onBlur={ ( event, editor ) => {

                                    } }
                                    onFocus={ ( event, editor ) => {

                                    } }
                                />
                            </Grid>

                        </Grid>
                        <Grid container className="boxAjustement2">
                            <Grid item xs={12} alignItems="center"
                                  justifyContent="center" className="boxAjustement">
                                <InputLabel htmlFor="outlined-adornment-amount" className='labelForm'>Signature</InputLabel>
                            </Grid>
                            <Grid item xs={12} alignItems="center"
                                  justifyContent="center" className="boxAjustement">
                                <CKEditor
                                    editor={ ClassicEditor }
                                    config={{
                                        wordCount: {
                                            maxCharCount: 100
                                        },
                                        extraKeys: {
                                            'Enter': 'br'
                                        },
                                        toolbar: [ 'bold', 'italic','textColor' ]
                                    }}
                                    data={signature}
                                    onReady={ editor => {
                                        // You can store the "editor" and use when it is needed.

                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();

                                        setSignature(data)
                                    } }
                                    onBlur={ ( event, editor ) => {

                                    } }
                                    onFocus={ ( event, editor ) => {

                                    } }
                                />
                            </Grid>

                        </Grid>
                        <Grid container className="boxAjustement2">
                            <Grid item xs={12} alignItems="center"
                                  justifyContent="center" className="boxAjustement">
                                <InputLabel htmlFor="outlined-adornment-amount" className='labelForm'>Image signature</InputLabel>
                            </Grid>
                            <Grid item xs={12} alignItems="center" justifyContent="center" className="boxAjustement">
                                <MyDropzone onDropzoneData={handleDropzoneData} existingImage={existingImage} idFiche={id}/>
                            </Grid>
                        </Grid>
                    {/*<Grid item xs={12} alignItems="center"*/}
                    {/*      justifyContent="center" className="boxAjustement">*/}
                    {/*    /!*<InputLabel htmlFor="outlined-adornment-amount">Signature</InputLabel>*!/*/}
                    {/*    <Controller*/}
                    {/*        name="signatureDelegation"*/}
                    {/*        control={control}*/}
                    {/*        defaultValue={signature}*/}
                    {/*        render={({ field: { onChange, value }, fieldState: { error } }) => (*/}
                    {/*            <TextField*/}
                    {/*                label="Signature"*/}
                    {/*                id="outlined"*/}
                    {/*                className="boxForm"*/}
                    {/*                variant="outlined"*/}
                    {/*                value={value}*/}
                    {/*                onChange={onChange}*/}
                    {/*                error={!!error}*/}
                    {/*                helperText={error ? error.message : null}*/}
                    {/*                FormHelperTextProps={{*/}
                    {/*                    component: 'div',*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*        rules={{ required: 'La signature est obligatoire' }}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                        <Grid item xs={12}  alignItems="center"
                              justifyContent="center" className="boxAjustement">
                            <Controller
                                name="link"
                                control={control}
                                defaultValue={link}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label="Lien signature"
                                        id="outlined"
                                        name="link"
                                        className="boxForm"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                                //rules={{ required: 'Le lien est obligatoire' }}
                            />

                        </Grid>


                        <Grid container className="boxAjustement2">
                            <Grid item xs={12} alignItems="center"
                                  justifyContent="center" className="boxAjustement">
                                <InputLabel htmlFor="outlined-adornment-amount" className='labelForm'>Active ?</InputLabel>
                            </Grid>

                            <Grid item xs={12} alignItems="center"
                                  justifyContent="center" className="boxAjustement">
                                <Stack direction="row" spacing={1} alignItems="center"
                                       justifyContent="center">
                                    <Typography>Non</Typography>
                                    <Controller
                                        name="is_default_select"
                                        control={control}
                                        defaultValue={active}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <AntSwitch
                                                checked={value}
                                                value={value}
                                                onChange={onChange}
                                                // className="boxForm"
                                            />
                                        )}
                                    />
                                    <Typography>Oui</Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} alignItems="center"
                              justifyContent="center" className="boxAjustement">
                            <Button
                                className="with-after_icon-akto btn-secondary-filled-step2"
                                onClick={(e) => returnPreviewsPage()}
                                variant="contained"
                                style={{marginRight:"20px"}}
                            >
                                Retour
                            </Button>
                            <Button className="with-after_icon-akto btn-primary-filled-step2" type="submit">Valider</Button>


                        </Grid>

                        { id &&
                            <Grid item xs={12} alignItems="center"
                                  justifyContent="center" className="boxAjustement">
                                <Button
                                    className="with-after_icon-akto btn-secondary-filled-step2"
                                    onClick={(e) => onDeleted(e, id)}
                                    variant="contained"
                                >
                                    Supprimer
                                </Button>

                            </Grid>
                        }

                    </Grid>
                </form>
            </Grid>
                    </Layout>
                </>
                )}
        </>
    )


}
