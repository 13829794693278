import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import useAuth from "../../security/auth";
import {Grid} from "@mui/material";

const pages = ['Liens', 'Catégories', 'Typologies', 'Fiches', 'Utilisateurs'];

function NavbarFront({titre}) {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const { login, logout, user } = useAuth();
    const token = localStorage.getItem('token');

    const handleLogout = () => {
        logout().then(r =>console.log("Déconnexion : LocalStorage réinitialisé.") )

    };

    return (
        <AppBar position="static" color="navbar">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Grid container >
                        <Grid xs={4} md={4}>
                            <LogoHeader />
                        </Grid>
                        <Grid xs={4} md={4} className="titreFrontNavBar">
                            <Typography variant="h1" style={{color:"#1d4851"}}>{titre}</Typography>
                        </Grid>
                        {token && (
                            <Grid xs={4} md={4} style={{textAlign:"right",paddingTop:"15px"}}>
                                <Button className="with-after_icon-akto btn-primary-filled" onClick={handleLogout}>Déconnexion</Button>
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavbarFront;