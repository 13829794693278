import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {deleteCategories, deleteDelegation, deleteLien} from "../../api/Api";
import Link from "@mui/material/Link";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Chip} from "@mui/material";
import {convertDate} from "../../function/function";
import MySwal from "sweetalert2";
import ClearIcon from '@mui/icons-material/Clear';



export default function DataGridLiens({data}) {

    console.log('data',data.dataLiens);
    const dataRecieve = data.dataLiens;
    let dataTable = dataRecieve;
    const navigate = useNavigate();


    // const  onDeleted =(e,param)=>{
    //     deleteCategories(param._id).then(async r => {
    //         if (r.status === 200) {
    //             await navigate('/liens')
    //         }
    //     });
    // }
    const  onDeleted =(e,param)=>{
        e.preventDefault();
        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Supprimer',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            deleteLien(param._id).then(async r => {
                if (r.status === 200){
                    window.location.reload();
                }
            });
        })

    }
    const onUpdate=(e,param)=>{
        navigate(`/documents/update/${param._id}`);
        // console.log(param)
    }

    const columns = [
        // { field: "_id", headerName: "ID", width: 200 },
        { field: "titre", headerName: "Document" , width: 300,renderCell: (params) => {
                return (
                    <>
                        <Link
                            onClick={(e) => onUpdate(e, params.row)}
                            variant="contained"
                        >
                            {params.row.titre}
                        </Link>

                    </>

                );
            } },
        { field: "categories", headerName: "Catégorie(s) liée(s)", width: 300,height:200 ,renderCell: (params) => (
                <ul className="flex">
                    {params.value.map((role, index) => (
                        <li key={index}>{role.titre}</li>
                    ))}
                </ul>
            )},

        // { field: "rang", headerName: "Rang", width: 100 },
        { field: "is_active", headerName: "Visible" , width: 100,renderCell: (params) => {
                if (params.row.is_active) {
                    return (
                        <>
                            <div>Oui</div>
                            {/*<Chip label="Actif" color="primary" variant="outlined" />*/}

                        </>

                    );
                }else {
                    return (
                        <>
                            <div>Non</div>
                            {/*<Chip label="Désactive" color="error" variant="outlined"/>*/}

                        </>

                    );
                }

            } },
        { field: "is_populaire", headerName: "Mise en avant" , width: 150,renderCell: (params) => {
                if (params.row.is_default_select) {
                    return (
                        <>
                            <div>Oui</div>
                            {/*<Chip label="Oui" color="primary" variant="outlined" />*/}

                        </>

                    );
                }else {
                    return (
                        <>
                            <div>Non</div>
                            {/*<Chip label="Non" color="error" variant="outlined"/>*/}

                        </>

                    );
                }

            } },
        { field: "is_default_select", headerName: "Sélection par défaut"  ,width: 200,renderCell: (params) => {
                if (params.row.is_default_select) {
                    return (
                        <>
                            <div>Oui</div>
                            {/*<Chip label="Oui" color="primary" variant="outlined" />*/}

                        </>

                    );
                }else {
                    return (
                        <>
                            <div>Non</div>
                            {/*<Chip label="Non" color="error" variant="outlined"/>*/}

                        </>

                    );
                }

            } },
        { field: "updatedAt", headerName: "Date de modification", width: 200,renderCell: (params) => {
                console.log(params)
                return <div >{convertDate(params.row.updatedAt)}</div>;
            }},
        { field: "web", headerName: "Lien",width: 100, renderCell: (params) => {
                return (
                    <>
                        <Link
                           href={params.row.web}
                           target="_blank"
                        >
                           <VisibilityIcon />
                        </Link>

                    </>

                );
            } },
        // { field: 'updatedAt', headerName: 'Modifier', width: 200, renderCell: (params) => {
        //         return (
        //             <>
        //                 <Button
        //                     onClick={(e) => onUpdate(e, params.row)}
        //                     variant="contained"
        //                 >
        //                     Mise à jour
        //                 </Button>
        //
        //             </>
        //
        //         );
        //     } },
        // { field: 'Supprimer', headerName: 'Supprimer', width: 100, renderCell: (params) => {
        //         return (
        //             <>
        //                 <a>
        //                     <ClearIcon
        //                         onClick={(e) => onDeleted(e, params.row)}
        //                         variant="contained"
        //                     />
        //                 </a>
        //
        //
        //
        //             </>
        //
        //         );
        //     } }
    ];
    // columns.forEach(column => {
    //     column.width = 250;
    // });

    return (
        <div style={{ height: '100%', maxWidth: '100%', margin: 'auto', overflow: 'auto' }}>
            <DataGrid
                rows={dataTable}
                getRowId={(row) => row._id}
                columns={columns}
                hideFooter={true}
                style={{ width: '100%' }}
                // getRowHeight={() => 100}
                autoHeight={true}
                // autoHeight={true}
            />
        </div>
    );
}
