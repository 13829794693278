import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, Select, Typography, TextField, MenuItem, Grid, Box } from "@mui/material";
import { useEffect, useState } from 'react';
import PreviewModule from "../../components/PreviewModule/PreviewModule";
import { getCategories, getTypologie } from "../../api/Api";
import FicheV2 from "../FicheFront/FicheV2";
import CategoriePreviews from "./CategoriePreviews";
import {Controller} from "react-hook-form";

export default function ModulePreviews() {
  const [checked, setChecked] = useState([true, false]);
  const [profil, setProfil] = useState("");
  // eslint-disable-next-line
  const [typologySelect, setTypologySelect] = useState([]);
  // eslint-disable-next-line
  const [liensPreview, setLiensPreview] = useState([]);
  // eslint-disable-next-line
  const [typologyPreview, setTypologyPreview] = useState();


  const handleChangeCategorie = (event) => {
    console.log(event)
  };
  const handleChange = (event) => {
    setProfil(event.target.value);
  };

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event) => {
    setChecked([checked[0], event.target.checked]);
  };

  useEffect(() => {
    getCategories().then((response) => {
      console.log(response);
    });

    getTypologie().then(r => {
      if (r.status === 200) {

        setTypologySelect(r.data)

      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        label="Négociant"
        control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
      />
      <FormControlLabel
        label="Acheteur de gros"
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </Box>
  );

  let liensData = [
    { web: "test.com", titre: "Test 1 " },
    { web: "test.com", titre: "Test 2" },
  ];
  let headerData = "test header";
  let footerData = "test footer";

  return (
    <Box padding={4} sx={{ flexGrow: 0 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
          <Box
            sx={{
              // minWidth: 300,
              // minHeight: 800,
              backgroundColor: "primary",
              borderRadius: 1,
              paddingLeft: 2,
            }}
          >
                <CategoriePreviews
                onChange={handleChangeCategorie}
                />

          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography variant="h6">Profils</Typography>
          <FormGroup>
            <Grid item xs={12}  alignItems="center"
                  justifyContent="center" className="boxAjustement">
              <InputLabel id="demo-select-small">Rôle</InputLabel>
                      <Select
                          id="outlined"
                          className="boxForm"
                          variant="outlined"
                          label="Role"
                          style={{minWidth:270}}

                      >
                        {typologySelect.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option.titre}
                            </MenuItem>
                        ))}
                      </Select>


            </Grid>
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h6">Prévisualisation du mail</Typography>
          <PreviewModule
            liens={liensData}
            header={headerData}
            footer={footerData}
          />
        </Grid>
      </Grid>
    </Box>
  );
}