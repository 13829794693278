// import React, {useEffect, useState} from 'react';
// import { useDropzone } from 'react-dropzone';
//
// function MyDropzone(props) {
//     const [files, setFiles] = useState([]);
//     const { getRootProps, getInputProps } = useDropzone({
//         accept: 'image/*',
//         onDrop: acceptedFiles => {
//             setFiles(acceptedFiles.map(file => Object.assign(file, {
//                 preview: URL.createObjectURL(file)
//             })));
//             props.onDropzoneData({ files });
//         }
//     });
//     useEffect(() => {
//         props.onDropzoneData({ files })
//     }, [files]);
//
//     const thumbs = files.map(file => (
//         <div key={file.name}>
//             <div>
//                 <img
//                     src={file.preview}
//                     alt={file.name}
//                     style={{ width: '100px' }}
//                 />
//             </div>
//         </div>
//     ));
//
//     return (
//         <section>
//             <div {...getRootProps()}>
//                 <input {...getInputProps()} />
//                 <p>Cliquez ici ou glissez-déposez des images pour les uploader</p>
//             </div>
//             <aside>
//                 {thumbs}
//             </aside>
//         </section>
//     );
// }
//
// export default MyDropzone;
import React, {useEffect, useState} from 'react';
import { useDropzone } from 'react-dropzone';
import Button from "@mui/material/Button";
import MySwal from "sweetalert2";
import {deletedImageApi} from "../api/Api.jsx";

function MyDropzone(props) {
    const [files, setFiles] = useState([]);
    const [active,setActive]=useState(true)
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            props.onDropzoneData({ files });
        }
    });
    useEffect(() => {
        props.onDropzoneData({ files })
        console.log("efffect *****")
        console.log(files)
        if(files.length>=1){
            setActive(false)
        }

    }, [files]);

    const thumbs = files.map(file => (
        <div key={file.name}>
            <div>
                     <img src={file.preview} alt={file.name} style={{ width: '100px' }} />

            </div>
        </div>
    ));

    const deletedImage = () => {
        MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Supprimer',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            if (result.isConfirmed) {
                deletedImageApi(props.idFiche).then(async r => {
                    console.log(r)
                    if (r.status === 200) {
                        MySwal.fire({
                                html:` <i>Image supprimée</i>`,
                                icon: 'success',
                                confirmButtonColor: '#00a79b',
                            }
                        ).then(async () => {
                            window.location.reload();
                        })

                    }else {
                        MySwal.fire({
                            html:` <i>Erreur dans la suppresion de l'image</i>`,
                            icon: 'error',
                            confirmButtonColor: '#00a79b',
                        })
                    }
                });
            }

        })

    }

    console.log('**************')
    console.log(props.existingImage)
    console.log(active);
    console.log('**************')

    return (
        <section>
            <div {...getRootProps()} style={{border:"solid 1px"}}>
                <input {...getInputProps()} />
                <p>Cliquez-ici ou glissez/déposez votre image </p>
            </div>
            <aside>
                {
                    props.existingImage && active

                        && <img src={props.existingImage}  style={{ width: '100px' }} />
                }
                {thumbs}
            </aside>
            {
                props.existingImage && active

                && <Button
                    className="with-after_icon-akto btn-secondary-filled-step2"
                    onClick={(e) => deletedImage()}
                    variant="contained"
                    style={{width:"21%",marginTop:"10px",marginBottom:"10px"}}
                >
                    Supprimer l'image
                </Button>
            }

        </section>
    );
}

export default MyDropzone;
