import React, {useContext, useEffect, useState} from "react";
import Main from "./Main/Main";

import { ThemeProvider } from '@emotion/react';
import { appThemeAkto } from "../themes/theme";
import NavbarAuth from "./NavBar/NavBar";
import NavBar from "./NavBar/navbarS";
import {getLoginStatus} from "../api/Api";
import {AuthContext} from "../components/ProtectedRoute/ProtectedRoute";


export default function Layout({ isAuthenticated,children}) {
  /**
   * Ici on créer la structure de l'application. C'est ici qu'on appelle le théme qui est défini dans le dossier 'themes'.
   * C'est ici qu'on intégre la logique si tu comptes créer un systéme de changement de théme (Light/Dark).
   */


  console.log("layout")
    console.log(isAuthenticated)
  return (
    <>
      <ThemeProvider theme={appThemeAkto}>
          {isAuthenticated ? <NavbarAuth /> : <NavBar />}
        <Main content={children} />
      </ThemeProvider>
    </>
  );
}
