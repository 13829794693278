import {Checkbox, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {createDelegation, createFiche, getDelegation, getOneUsers, getTypologie, updateDelegation} from "../../api/Api";
import Skeleton from "@mui/material/Skeleton";
import {Controller, useForm} from "react-hook-form";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import LayoutFront from "../../layout/LayoutFront";
import MySwal from "sweetalert2";
import {de} from "date-fns/locale";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";


export default function Step2() {
    const navigate = useNavigate();
    const { data } = useParams();
    const [step1Data ,setStep1Data]=useState(JSON.parse(data))
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [dataTypologie, setDataTypologie] = useState();
    const { register, control,formState: { errors }, handleSubmit } = useForm();
    const [email ,setEmail]=useState("");
    const [agreement,setAgreement]=useState(false);
    const [valid,setValid]=useState(false);
    const [delegation ,setDelegation]=useState("");
    const [trueCount ,setTrueCount] =useState(0);
    const SkeletonTable = <Skeleton sx={{bgcolor: 'grey.900'}}
                                    variant="rectangular"
                                    width={210}
                                    height={118}
    />
    console.log("step 2")
    console.log(step1Data)

    const countTrue = (obj) => {
        return Object.values(obj).filter(value => value === true).length;
    };
    const returnPreviewsPage = () => {
        navigate('/fiches')
    };

    useEffect(() => {
        getTypologie()
            .then(resp => {
                setDataTypologie(resp.data);
                setLoadingStatus(false);
                setTrueCount(countTrue(step1Data))
                console.log(resp.data)
            }).catch(err => {
            console.log('error from fetching categories', err);
        })


    }, []);
    console.log(dataTypologie)
    const labelWithLinkStyles = {
        display: 'flex',
        alignItems: 'center',
    };
    const onSubmit = (form,e) => {

        let selected = JSON.parse(data);

        for (let key in selected) {
            if (selected.hasOwnProperty(key) && selected[key] === false) {
                delete selected[key];
            }
        }

        let resultStr = JSON.stringify(selected);


        MySwal.fire({
            title: 'Voulez-vous confirmer votre envoi ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Valider',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                createFiche(form,resultStr).then(r => {
                    if (r.status === 200) {
                        MySwal.fire({
                                html:` <i>${r.data.message}</i>`,
                                icon: 'success',
                                confirmButtonColor: '#00a79b',
                            }
                        ).then(async () => {
                            await navigate('/fiches')
                        })

                    }else {
                        MySwal.fire({
                            html:` <i>${r.data.message}</i>`,
                            icon: 'error',
                            confirmButtonColor: '#00a79b',
                        })
                    }
                });
            }
        })
        //console.log(test);



    };
    return(
        <>
            {loadingStatus === true ? (
                <LoadingIndicator/>
            ) : (
                <>
                    <LayoutFront titre={"Fiches métiers"}>
                        <Grid container className="containerCheckBoX" style={{marginTop:"40px"}}>
                            <h2>Veuillez renseigner les informations du destinataire des <strong>{trueCount} fiches sélectionnées</strong></h2>
                        </Grid>
                        <div className="header-separator">
                            <div className="header-separator-left"></div>
                            <div className="header-separator-angle"></div>
                            <div className="header-separator-right"></div>
                        </div>

                        <Grid container
                              spacing={0}
                            // direction="column"
                              alignItems="center"
                              justifyContent="center"
                              style={{ minHeight: '50vh'}}
                              rowSpacing={5}
                              columnSpacing={{ xs:4, sm: 4, md: 4 }}
                        >

                            <form  onSubmit={handleSubmit(onSubmit)}>

                                <Grid item xs={6} md={12}  alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue={email}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TextField
                                                label="Adresse E-mail"
                                                id="outlined"
                                                name="titre"
                                                className="boxForm"
                                                value={value}
                                                style={{minWidth:400}}
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                                inputProps={{ pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }}
                                            />
                                        )}
                                        rules={{
                                            required: "L'email est obligatoire",
                                            validate: value => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) || "L'email doit être au bon format"
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={12}  alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <InputLabel>Profil</InputLabel>
                                    <Controller
                                        name="typologie"
                                        control={control}
                                        defaultValue={delegation}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Select
                                                value={(e)=>value,setValid(true)}
                                                label="Profil"
                                                id="outlined"
                                                name="titre"
                                                className="boxForm"
                                                onChange={onChange}
                                                style={{minWidth:400}}
                                                error={!!error}
                                                helpertext={error ? error.message : null}
                                            >
                                                {dataTypologie.map((option, index) => (
                                                    <MenuItem key={index} value={option._id}>
                                                        {option.titre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                        rules={{ required: "Le profil est obligatoire" }}

                                    />
                                </Grid>

                                {!valid && (
                                    <Grid item xs={12} alignItems="center" justifyContent="center">
                                        <p style={{color:"red",fontFamily:"Mont",fontSize:"14px"}}>Le profil doit être séléctionné</p>
                                    </Grid>
                                )}

                                <Grid item xs={12}  alignItems="center"
                                      justifyContent="center" className="boxAjustement">
                                    <Controller
                                        name="agreement"
                                        control={control}
                                        defaultValue={agreement}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <FormControlLabel
                                                className={`checkBoxAgreement ${error ? 'error' : ''}`}
                                                control={
                                                    <Checkbox
                                                        onChange={onChange}
                                                        checked={(e)=>
                                                            value,
                                                            setAgreement(value)
                                                        }
                                                        helpertext={error ? error.message : "La case d'acceptation est obligatoire"}
                                                    />
                                                }
                                                label={
                                                    <>
                        <span style={labelWithLinkStyles}>
                            J'accepte les&nbsp;
                            <Link
                                href="https://www.akto.fr/politique-de-confidentialite/"
                                style={{ marginLeft: "2px" }}
                                target={"_blank"}
                            >
                                Conditions Générales d'Utilisation des données Personnelles
                            </Link>
                        </span>
                                                    </>
                                                }
                                                helpertext={error ? error.message : "La case d'acceptation est obligatoire"}
                                            />
                                        )}
                                        rules={{ required: "La case d'acceptation est obligatoire" }}
                                    />
                                </Grid>

                                {!agreement && (
                                    <Grid item xs={12} alignItems="center" justifyContent="center">
                                        <p style={{color:"red",fontFamily:"Mont",fontSize:"14px"}}>Veuillez accepter les conditions d'utilisation pour continuer.</p>
                                    </Grid>
                                )}




                                <Grid container
                                      spacing={0}
                                    // direction="column"
                                      alignItems="center"
                                      justifyContent="center"

                                >
                                    <Grid item xs={6} md={6}>
                                        <Button className="with-after_icon-akto btn-secondary-filled-step2" onClick={returnPreviewsPage} type="button">Retour</Button>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Button className="with-after_icon-akto btn-primary-filled-step2" type="submit">Envoyer</Button>
                                    </Grid>
                                </Grid>

                            </form>
                        </Grid>
                    </LayoutFront>


                </>

            )}
        </>
    )
}