import {Grid} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {getCategories, getFiche, getLien, getTypologie} from "../../api/Api";
import Skeleton from "@mui/material/Skeleton";
import ViewWithIndeterminateCheckboxesPreviews from "./ParentAndChildCheckboxPreview";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";



export default function CategoriePreviews() {

    const [fiches ,setFiches]=useState();
    const [categories,setCategorie]=useState();
    const [allData,setAllData]=useState()
    const [loadingStatus, setLoadingStatus] = useState(true);
    const { categoriePreviews } = useContext(AuthContext);
    const [typologie,setTypologie]=useState();
    const SkeletonTable = <Skeleton sx={{bgcolor: 'grey.900'}}
                                    variant="rectangular"
                                    width={210}
                                    height={118}
    />

    useEffect(() => {
        console.log('Context:', AuthContext);
        console.log('categoriePreviews:', categoriePreviews);
    }, [categoriePreviews]);
    // useEffect(() => {
    //
    // }, []);

    function categorizeFiches(categories, fiches) {
        const categorizedFiches = categories.reduce((result, category) => {
            result[category.titre] = [];
            return result;
        }, {});

        fiches.forEach(fiche => {
            const matchingCategories = fiche.categories.filter(ficheCategory =>
                categories.some(category => category._id === ficheCategory._id)
            );

            matchingCategories.forEach(matchingCategory => {
                categorizedFiches[matchingCategory.titre].push(fiche);
            });
        });

        return categorizedFiches;
    }
    async function fetchData() {
        const data1 = await  getLien().then( async r=>{
                //console.log(r.data)
                setFiches(r.data)
             return r.data
            }
        );
        const data2 = await  getCategories().then(async r=>{
            setCategorie(r.data)
            return r.data
        });
        return Promise.all([data1, data2]).then(([data1, data2]) => {
            console.log(data1)
            setAllData(categorizeFiches(data2,data1));

        });
    }


    useEffect(() => {

       fetchData().then(async r=>{
          setLoadingStatus(false)
       })

        //setAllData(categorizeFiches(categories,fiches));

    }, []);

    console.log(allData)

    return(
        <>
        {loadingStatus === true ? (
            SkeletonTable
        ) : (
            <>

                    <Grid container className="containerCheckBoX">
                        <ViewWithIndeterminateCheckboxesPreviews  data={allData}/>
                    </Grid>


            </>

        )}
        </>
    )

}