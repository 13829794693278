import React, { useEffect, useState } from "react";
import {PieChart, Pie, Cell, Tooltip, ResponsiveContainer} from "recharts";
import LoadingIndicator from "./LoadingIndicator.jsx";


const CustomActiveShapePieChart = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 20) * cos;
    const sy = cy + (outerRadius + 20) * sin;
    const mx = cx + (outerRadius + 40) * cos;
    const my = cy + (outerRadius + 40) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 32;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
        <g>
            <text  x={ex + (cos >= 0 ? 1 : -1) * 12}
                  y={ey}
                  dy={5}
                   textAnchor={textAnchor}
                   // fill={props.fill}
                   style={{ fontSize: 12 }}

            >
                {props.name +" "+ `${(percent * 100).toFixed(2)}%`}
                {/*<tspan>{props.name.split(",")[0]}</tspan>*/}
                {/*<tspan x={cx} dy={20}>{props.name.split(",")[1]}</tspan>*/}
            </text>
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={props.fill}
                strokeWidth={1}
                fill="none"
            />
        </g>
    );
};

const PieChartComponent = ({ data }) => {
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const colors = [
            "#49F0B1",
            "#36C3A8",
            "#0048BE",
            "#001B9C",
            "#7F45C4",
            "#592B8F",
            "#FF708F",
            "#C93857",
            "#FFA807",
            "#FF6707"
        ];
        setLoading(true);
        const profiles = {};
        data.forEach((item) => {

            let filliere = item.filliere;
            console.log("piechart")
            console.log(item)
            // corrigez l'orthographe si nécessaire
            if (filliere == "Hotels, cafés, restaurants") {
                console.log("hhhhhhh")// mettez ici la mauvaise orthographe
                filliere = "Hôtels, cafés, restaurants"; // correction
            }

            if (!profiles[filliere]) {
                profiles[filliere] = 1;
            } else {
                profiles[filliere] += 1;
            }
        });

        const sortedProfiles = Object.entries(profiles)
            .sort((a, b) => a[0].localeCompare(b[0])) // Compare the names
            .map(([key, value], index) => ({
                name: key,
                value: value,
                fill: colors[index % colors.length],
            }));

        setChartData(sortedProfiles);
        setLoading(false);
    }, [data]);

    if (chartData.length === 0) {
        return <p>Aucun résultat.</p>
    }

    return (
        <ResponsiveContainer width="100%" height={650}>
            {loading ? (
                <LoadingIndicator />
            ) : (

                <PieChart width={400} height={400}>
                    <Pie
                        dataKey="value"
                        data={chartData}
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={170}
                        outerRadius={250}
                        fill="#8884d8"
                      //  labelLine={true}
                        startAngle={90}
                        endAngle={-270}
                        label={<CustomActiveShapePieChart/>}

                    >
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>

            )}
        </ResponsiveContainer>
    );
};

export default PieChartComponent;