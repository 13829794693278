import {getCategories, getDelegation, getStats} from "../../api/Api";
import {useContext, useEffect, useState} from "react";
import Layout from "../../layout/Layout";
import {Checkbox, FormControlLabel, Grid, Tab, Tabs, TextField, Typography, Box} from "@mui/material";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";
import DatePicker, {registerLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';
import LoadingIndicator from "../../components/LoadingIndicator.jsx";
import {SyntheticEvent} from "react";
import StatBranche from "./statBranche.jsx";
import StatDelegation from "./statDelegation.jsx";
import StatProfils from "./statProfils.jsx";

registerLocale('fr', fr)


export default function Stats() {



    const {isAuthenticated} = useContext(AuthContext);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const SkeletonTable = <LoadingIndicator
    />

    function TabPanel(props: TabPanelProps) {
        const {children, value, index, ...other} = props;

        return (

            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{p: 3}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>

        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <Layout isAuthenticated={isAuthenticated}>
                        <Grid   justify="center" alignItems="center" className="gridCustom2">
                            <Grid item xs={12} className="containerTitre2">
                                <Typography variant="h1">Statistiques</Typography>
                                <Grid container spacing={0} justify="center" alignItems="center" className="gridCustom2">
                                <Box  sx={{borderBottom: 0}}>
                                    <Tabs TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "white"
                                        }
                                    }} value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label="Par branches" {...a11yProps(0)}
                                             style={{
                                                 color: value === 0 ? '#1d4851' : 'white',
                                                 backgroundColor: value === 0 ? 'white' : 'transparent'
                                             }}
                                        />
                                        <Tab label="Par délégations / utilisateurs"
                                             style={{
                                                 color: value === 1 ? '#1d4851' : 'white',
                                                 backgroundColor: value === 1 ? 'white' : 'transparent'
                                             }}
                                        />
                                        <Tab label="Par profils" {...a11yProps(2)}
                                             style={{
                                                 color: value === 2 ? '#1d4851' : 'white',
                                                 backgroundColor: value === 2 ? 'white' : 'transparent'
                                             }}
                                        />
                                    </Tabs>
                                </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={0} justify="center" alignItems="center" className="gridCustom2">
                                <TabPanel value={value} index={0}>
                                    <StatBranche/>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <StatDelegation />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <StatProfils/>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Layout>

                </>
            )}
        </>
    );


}