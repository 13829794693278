import React, { useState, useEffect } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Cell,
    LabelList,
    ResponsiveContainer
} from 'recharts';

const HistogramV2 = ({ data }) => {
    const [chartData, setChartData] = useState([]);
    const colors = [
        "#49F0B1",
        "#36C3A8",
        "#0048BE",
        "#001B9C",
        "#7F45C4",
        "#592B8F",
        "#FF708F",
        "#C93857",
        "#FFA807",
        "#FF6707"
    ];

    useEffect(() => {
        const fillieres = [];
        const counts = {};

        data.forEach(item => {
            item.filliere.forEach(filliere => {

                // corrigez l'orthographe si nécessaire
                if (filliere === "Hotels, cafés, restaurants") { // mettez ici la mauvaise orthographe
                    filliere = "Hôtels, cafés, restaurants"; // correction
                }

                if (!fillieres.includes(filliere)) {
                    fillieres.push(filliere);
                }
                if (counts[filliere]) {
                    counts[filliere] += 1;
                } else {
                    counts[filliere] = 1;
                }
            });
        });
        // Trier les fillieres par ordre alphabétique
        fillieres.sort();
        setChartData(fillieres.map(filliere => ({ filliere, fiches: counts[filliere] })));
    }, [data]);

    if (chartData.length === 0) {
        return <p>Aucun résultat.</p>
    }


    return (
        <ResponsiveContainer width="100%" height={700}>
            <BarChart
                data={chartData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="filliere"
                    height={400}
                    tickSize
                    interval={0}
                    tick={(props) => {
                        const { x, y, payload } = props;
                        console.log(payload.value)
                        return (
                            <g>
                                <line x1={x} y1={y + 10} x2={x} y2={280} stroke="#8884d8" />
                                <text
                                    x={x}
                                    y={y + 30}
                                    dy={25}
                                    textAnchor="end" // ajuste l'inclinaison des étiquettes
                                    fill="#666"
                                    transform={`rotate(-50, ${x}, ${y + 30})`} // applique une rotation à chaque étiquette
                                >
                                    {payload.value}
                                </text>
                            </g>
                        );
                    }}
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="fiches" fill="#8884d8">
                    <LabelList dataKey="fiches" position="top" fill="#000" />
                    {
                        chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default HistogramV2;
