import axios from "axios";
import {de} from "date-fns/locale";

/* Base URL */
//const baseURL = "http://localhost:5000/api";
const baseURL = "https://promotion.akto.fr/api";
//const baseURL = "https://devakto.pointvirgule.net/api";
//const baseURL = "https://dev10.pointvirgule.net/api";
console.log("api")
console.log(localStorage.getItem('token'))
/* Header [x-access-token] */

/* Request for Categories */

export async function createCategories(categoriesData) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try{
        let status = {};
        const axiosData = {
            "titre": categoriesData?.titre,
            "rang": categoriesData?.rang,
            "is_active": categoriesData?.isActive
        };
        await instanceAxios.post(`${baseURL}/categories/`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        console.log(error);
    }
}

export async function updateCategories(id, categoriesData) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        const axiosData = {
            "titre": categoriesData?.titre,
            "rang": categoriesData?.rang,
            "is_active": categoriesData.isActive
        };
        await instanceAxios.put(`${baseURL}/categories/${id}`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        console.error(error);
    }
}
export async function udpateRangApi(categories) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {

        let status = {};
        const axiosData = {
            "caterories": categories,
        };
        await instanceAxios.post(`${baseURL}/categories/rang/`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteCategories(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.delete(`${baseURL}/categories/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getCategories() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/categories/`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        return "error";
    }
}

export async function getOneCategories(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/categories/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        return "error";
    }
}

/* Request for delegation */

export async function createDelegation( delegationData,fData,salutation,signature) {
    console.log("api **********************")
    console.log(fData)
    console.log(delegationData)
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token'), 'Content-Type': 'multipart/form-data'}
    })
    try{
        let status = {};
        const formData = new FormData();
        formData.append('nom_delegation', delegationData?.nomDelegation);
        formData.append('signature_delegation', signature);
        formData.append('is_active', fData.is_default_select);
        formData.append('salutation', salutation);
        if (fData.files && fData.files.length > 0) {
            formData.append('image', fData.files[0], fData.files[0].name);
        }
        formData.append('link', delegationData?.link);


        await instanceAxios.post(`${baseURL}/delegation/`, formData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        console.log(error);
    }
}

export async function updateDelegation(id, delegationData,fData,salutation,signature) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        const formData = new FormData();
        formData.append('nom_delegation', delegationData?.nomDelegation);
        formData.append('signature_delegation', signature);
        formData.append('is_active', delegationData.is_default_select);
        formData.append('salutation', salutation);
        if (fData.files && fData.files.length > 0) {
            formData.append('image', fData.files[0], fData.files[0].name);
        }
        formData.append('link', delegationData?.link);
        await instanceAxios.put(`${baseURL}/delegation/${id}`, formData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteDelegation(id) {
    console.log()
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.delete(`${baseURL}/delegation/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getDelegation() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/delegation/`)
            .then(response => {
                status = response;
                console.log("data delegation"+response)
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        return "error";
    }
}

export async function getOneDelegation(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/delegation/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        return "error";
    }
}

/* Request for fiche */

export async function createFiche(form,data) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try{
        let status = {};
        const axiosData = {
            "typologie": form?.typologie,
            "consentement": form?.agremment,
            "liens": data,
            "email": form.email
        };
        await instanceAxios.post(`${baseURL}/fiche/`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        console.log(error);
    }
}

export async function deleteFiche(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.delete(`${baseURL}/fiche/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getFiche() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    console.log("api")
    console.log(localStorage.getItem('token'))
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/fiche/`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getOneFiche(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/fiche/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

/* Request for Lien */

export async function createLien(lienData,cat) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        const axiosData = {
            "titre": lienData?.titre,
            "web": lienData?.web,
            "categories": cat,
            "rang":lienData?.rang,
            'is_active':lienData?.is_active,
            'is_default_select':lienData?.is_default_select,
            'is_populaire':lienData?.is_populaire
        }
        await instanceAxios.post(`${baseURL}/liens/`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function updateLien(lienData,cat, id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        const axiosData = {
            "titre": lienData?.titre,
            "web": lienData?.web,
            "categories": cat,
            "rang":lienData?.rang,
            'is_active':lienData?.is_active,
            'is_default_select':lienData?.is_default_select,
            'is_populaire':lienData?.is_populaire
        }
        await instanceAxios.put(`${baseURL}/liens/${id}`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function deleteLien(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.delete(`${baseURL}/liens/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })

        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getLien() {

    const instanceAxios2 = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios2.get(`${baseURL}/liens/`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getOneLien(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/liens/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getListLien() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/liens/list`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

/* Request for Typologie */

export async function createTypologie(typologieData,message,textSup) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    console.log("info create")
    console.log(typologieData);
    try {
        let status = {};
        const axiosData = {
            "titre": typologieData?.titre,
            "mail_template": message,
            "textSup":textSup,
            'is_active':typologieData?.is_active
        }
        await instanceAxios.post(`${baseURL}/typologie/`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function updateTypologie(id, typologieData,message,texSup) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        const axiosData = {
            "titre": typologieData?.titre,
            "mail_template": message,
            "textSup":texSup,
            'is_active':typologieData?.is_active
        }
        await instanceAxios.put(`${baseURL}/typologie/${id}`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        console.log(error);
    }
}

export async function deleteTypologie(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.delete(`${baseURL}/typologie/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getTypologie() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/typologie/`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getOneTypologie(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/typologie/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

/* Request for Utilisateur */

export async function createUser(userData) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        const axiosData = {
            "first_name": userData?.first_name,
            "last_name": userData?.last_name,
            "email": userData?.email,
            "delegation": userData?.delegation,
            'is_active':userData?.is_active,
            'role':userData?.role
        }
        await instanceAxios.post(`${baseURL}/users/`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function loginUser(userData) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    console.log(userData)
    try {
        let status = {};
        const axiosData = {
            "email": userData?.email,
            "password": userData?.password
        }
        await instanceAxios.post(`${baseURL}/users/login`, axiosData,{timeout: 10000})
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}
export async function loginConseiller(delegation,password) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })

    try {
        let status = {};
        const axiosData = {
            "delegationId": delegation,
            "password": password
        }
        await instanceAxios.post(`${baseURL}/users/conseiller-login`, axiosData, {timeout: 10000})
            .then(response => {
                status = response;
                console.log(response)
            }).catch(error => {
                console.log(error)
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function updateUser(id, userData) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        const axiosData = {
            "first_name": userData?.first_name,
            "last_name": userData?.last_name,
            "email": userData?.email,
            "delegation": userData?.delegation,
            'is_active':userData?.is_active,
            'role':userData?.role
        }
        await instanceAxios.put(`${baseURL}/users/${id}`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function deleteUser(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.delete(`${baseURL}/users/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getUsers() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/users/`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error)
    }
}

export async function getOneUsers(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/users/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getLoginStatus() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/users/login/status`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

/* Request for logs */

export async function getLogs() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/logs/`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function sendLogs() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/logs/send_logs`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error)
    }
}

/* Request for Stats */

export async function getStats() {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 5000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/stats/`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function getDataForStats(statsData) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        const axiosData = {
            "date_mini": statsData?.dateMini,
            "date_maxi": statsData?.dataMaxi,
            "region": statsData?.region
        }
        await instanceAxios.get(`${baseURL}/stats/general`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}

export async function apiSenPassword(data,userId,token) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        // headers: {'x-access-token': localStorage.getItem('token')}
    })

    try {
        let status = {};
        const axiosData = {
            "password":data.password
        }
        await instanceAxios.post(`${baseURL}/password-reset/${userId}/${token}`,axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}
export async function apiSenPasswordAdm(mail) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        // headers: {'x-access-token': localStorage.getItem('token')}
    })

    try {
        let status = {};
        const axiosData = {
            "email":mail.email
        }
        await instanceAxios.post(`${baseURL}/users/passwordSend/`,axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}
export async function check(userId,token) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        // headers: {'x-access-token': localStorage.getItem('token')}
    })

    try {
        let status = {};
        const axiosData = {
            "user":userId,
            "password":token
        }
        await instanceAxios.get(`${baseURL}/users/login/tokencheck/${userId}/${token}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}


export async function fetchPreview(liens, salutation, signature,delegationId,image_link,image) {
    try {
        const response = await axios.post(`${baseURL}/fiche/preview`, {
            liens,
            salutation,
            signature,
            delegationId,
            image_link,
            image
        });

        const template = response.data;

        return {
            template,
            data: {
                liens,
                salutation,
                signature,
                delegationId,
                image_link,
                image
            }
        };
    } catch (error) {
        console.error(error);
    }
}

export async function sendRecovery(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {
        let status = {};
        await instanceAxios.get(`${baseURL}/users/recovery/${id}`)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch(error) {
        console.log(error);
    }
}
export async function deletedImageApi(id) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: {'x-access-token': localStorage.getItem('token')}
    })
    try {

        let status = {};
        const axiosData = {
            "id": id,
        };
        await instanceAxios.post(`${baseURL}/delegation/deletedImage/`, axiosData)
            .then(response => {
                status = response;
            }).catch(error => {
                status = error;
            })
        return status;
    } catch (error) {
        console.error(error);
    }
}

export async function top10JobsMetierDistribue(date1, date2,delegations) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') }
    });
    try {
        let status = {};
        await instanceAxios.get(`/stats/top10JobsMetierDistribue?startDate=${date1}&endDate=${date2}&delegations=${delegations}`)
            .then(response => {
                status = response;
            })
            .catch(error => {
                status = error;
            });
        return status;
    } catch (error) {
        console.error(error);
    }
}
export async function generateXlsxMetierDistribue(date1, date2,delegations) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') },
        responseType: 'blob'  // Ajouter cette ligne
    });

    try {
        const response = await instanceAxios.get(`/stats/generateXlsxMetierDistribue?startDate=${date1}&endDate=${date2}&delegations=${delegations}`);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Nb_fiches_distribuees.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function envoispardelegation(date1, date2) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') }
    });
    try {
        let status = {};
        await instanceAxios.get(`/stats/envoispardelegation?startDate=${date1}&endDate=${date2}`)
            .then(response => {
                status = response;
            })
            .catch(error => {
                status = error;
            });
        return status;
    } catch (error) {
        console.error(error);
    }
}
export async function top10Users(date1, date2,delegation) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') }
    });
    let d;

    console.log("**tot*******")
    console.log("*********")
    try {
        let status = {};
        await instanceAxios.get(`/stats/top10Users?startDate=${date1}&endDate=${date2}&delegation=${delegation}`)
            .then(response => {
                status = response;
            })
            .catch(error => {
                status = error;
            });
        return status;
    } catch (error) {
        console.error(error);
    }
}
export async function generateXlsxUser(date1, date2,delegation) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') },
        responseType: 'blob'  // Ajouter cette ligne
    });
    let d;


    try {
        const response = await instanceAxios.get(`/stats/exportUsers?startDate=${date1}&endDate=${date2}&delegation=${delegation}`);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Repartision_envoie_conseiller.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function ficheParTypologie(date1, date2,delegation) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') }
    });
    try {
        let status = {};
        await instanceAxios.get(`/stats/ficheParTypologie?startDate=${date1}&endDate=${date2}&delegation=${delegation}`)
            .then(response => {
                status = response;
            })
            .catch(error => {
                status = error;
            });
        return status;
    } catch (error) {
        console.error(error);
    }
}
export async function popularite(startDate, endDate, delegations, profil, categorie) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') }
    });

    let status = {};

    let delegationsParam = delegations.join(',');

    try {
        await instanceAxios.get(`/stats/ficheParPopularite?startDate=${startDate}&endDate=${endDate}&delegations=${delegationsParam}&profil=${profil}&categorie=${categorie}`)
            .then(response => {
                status = response;
            })
            .catch(error => {
                status = error;
            });
    } catch (error) {
        console.error(error);
    }

    return status;
}
export async function exportProfilsByBranches(date1, date2,profil,branche) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') },
        responseType: 'blob'  // Ajouter cette ligne
    });
    let d;


    try {
        const response = await instanceAxios.get(`/stats/exportProfilsByBranches?startDate=${date1}&endDate=${date2}&profil=${profil}`);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Envoie_branche.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function exportFichesByProfil(date1, date2,profil,branche) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') },
        responseType: 'blob'  // Ajouter cette ligne
    });
    let d;


    try {
        const response = await instanceAxios.get(`/stats/exportFichesByProfil?startDate=${date1}&endDate=${date2}&profil=${profil}&categorie=${branche}`);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Envoie_fiche.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getFichesByBranch(date1, date2,delegation) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') }
    });
    try {
        let status = {};
        await instanceAxios.get(`/stats/getFichesByBranch?startDate=${date1}&endDate=${date2}&delegation=${delegation}`)
            .then(response => {
                status = response;
            })
            .catch(error => {
                status = error;
            });
        return status;
    } catch (error) {
        console.error(error);
    }
}
export async function exportFichesByBranch(date1, date2) {
    const instanceAxios = axios.create({
        baseURL: baseURL,
        timeout: 1000,
        headers: { 'x-access-token': localStorage.getItem('token') },
        responseType: 'blob'  // Ajouter cette ligne
    });
    let d;


    try {
        const response = await instanceAxios.get(`/stats/export?startDate=${date1}&endDate=${date2}`);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Repartision_profil.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response;
    } catch (error) {
        console.error(error);
    }
}
