import {getCategories, getUsers} from "../../api/Api";
import {useContext, useEffect, useState} from 'react';
import Skeleton from '@mui/material/Skeleton';
import {Grid, Typography, Item} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {appThemeAkto} from "../../themes/theme";
import Button from "@mui/material/Button";
import formulaireCat from "../../components/CRUD_categorie/formulaireCat";
import {useNavigate} from "react-router-dom";
import DataGridUser from "./DataGridUser";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";
import Layout from "../../layout/Layout";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";
// import Link  from "react-router-dom";

export default function UserPage() {

    const [dataUser, setDataUser] = useState();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext)

    const SkeletonTable = <LoadingIndicator
    />


    useEffect(() => {
        getUsers()
            .then(resp => {
                setDataUser(resp.data);
                setLoadingStatus(false);
            }).catch(err => {
            console.log('error from fetching categories', err);
        })
    }, [])

    useEffect(() => {
    }, [dataUser])


    return (
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <Layout isAuthenticated={isAuthenticated}>
                    <Grid container spacing={5} justify="center" alignItems="center">
                        <Grid item xs={12} className="containerTitre">
                            <Typography variant="h1">Comptes utilisateurs</Typography>
                        </Grid>
                        <Grid item xs={8}>
                        </Grid>
                        <Grid item xs={4} className="gridCustom">
                            <Button className="with-after_icon-akto btn-primary-filled" onClick={() => navigate('/comptes/ajout')}>Ajouter un utilisateur</Button>
                        </Grid>
                        <Grid container className="tabuser" justify="center" alignItems="center" style={{marginLeft:"81px!important"}}>
                            {/*<Grid item xs={2}>*/}
                            {/*</Grid>*/}
                        <Grid item xs={12} >
                            <DataGridUser data={{dataUser}} />
                            {/*<Grid item xs={1}>*/}
                            {/*</Grid>*/}
                        </Grid>
                            <Grid container spacing={5} justify="center" alignItems="center" style={{paddingTop:"200px"}}>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Layout>

                </>

            )}
        </>
    );

}
