import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {deleteCategories, deleteDelegation, udpateRangApi} from "../../api/Api";
import Link from "@mui/material/Link";
import {Chip} from "@mui/material";
import {convertDate} from "../../function/function";
import {useState} from "react";




export default function DataGridCategorie({data}) {

    const [dataRecieve, setDataTable] = useState(data.dataCategorie.map((item, index) => ({ ...item, rangN: index })));
   // const [dataRecieve,setDataTable] = useState(data.dataCategorie);
    let dataTable = dataRecieve;
    const navigate = useNavigate();


    const  onDeleted =(e,param)=>{
        deleteCategories(param._id).then(async r => {
            if (r.status === 200) {
                await navigate('/categories')
            }
        });
    }
    const onUpdate=(e,param)=>{
        navigate(`/categorie/update/${param._id}`);
       // console.log(param)
    }
    const updateRank = (id, newRank) => {
        const newDataTable = [...dataRecieve];
        const itemToMove = newDataTable.find((item) => item._id === id);
        if (itemToMove) {
            const currentIndex = newDataTable.indexOf(itemToMove);
            if (newRank >= 0 && newRank < newDataTable.length) {
                // First, update the rangN of all the items between the current position and the new position
                const start = Math.min(currentIndex, newRank);
                const end = Math.max(currentIndex, newRank);
                for (let i = start; i <= end; i++) {
                    if (i === currentIndex) {
                        // Skip the item that is being moved
                        continue;
                    }
                    newDataTable[i].rangN = newRank > currentIndex ? newDataTable[i].rangN - 1 : newDataTable[i].rangN + 1;

                }

                // Then, move the item to the new position
                newDataTable.splice(currentIndex, 1);
                newDataTable.splice(newRank, 0, { ...itemToMove, rangN: newRank });
                setDataTable(newDataTable);
                console.log("rang cat *************")
                console.log(newDataTable)
                udpateRangApi(newDataTable).then((async r => {
                    if (r.status === 200) {
                        console.log("ok ")
                    }
                }))
                console.log("*******************")
            }
        }
    };


    // const updateRank = (id, newRank) => {
    //
    //     const newDataTable = [...dataRecieve];
    //     const itemToMove = newDataTable.find((item) => item._id === id);
    //     if (itemToMove) {
    //         const currentIndex = newDataTable.indexOf(itemToMove);
    //         if (newRank >= 0 && newRank < newDataTable.length) {
    //             newDataTable.splice(currentIndex, 1);
    //             newDataTable.splice(newRank, 0, { ...itemToMove, rangN: newRank });
    //             setDataTable(newDataTable);
    //             console.log("rang cat *************")
    //             console.log(newDataTable)
    //             udpateRangApi(newDataTable).then((async r => {
    //                 if (r.status === 200) {
    //                     console.log("ok ")
    //                 }
    //             }))
    //             console.log("*******************")
    //         }
    //     }
    // };
    // const moveUp = (index) => {
    //     console.log(index)
    //     const newDataTable = [...dataRecieve];
    //     if (index > 0) {
    //         const temp = newDataTable[index];
    //         newDataTable[index] = newDataTable[index - 1];
    //         newDataTable[index - 1] = temp;
    //         setDataTable(newDataTable);
    //     }
    // };
    //
    // const moveDown = (index) => {
    //     const newDataTable = [...dataRecieve];
    //     if (index < newDataTable.length - 1) {
    //         const temp = newDataTable[index];
    //         newDataTable[index] = newDataTable[index + 1];
    //         newDataTable[index + 1] = temp;
    //         setDataTable(newDataTable);
    //     }
    // };

    const columns = [
        // { field: "_id", headerName: "ID", width: 200 },
        // {
        //     headerName: 'Classement',
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <div style={{ display: 'flex', alignItems: 'center' }}>
        //                     <div onClick={() => moveUp(params.rowIndex)}>⬆️</div>
        //                     <div>{params.row.rang}</div>
        //                     <div onClick={() => moveDown(params.rowIndex)}>⬇️</div>
        //                 </div>
        //             </>
        //         );
        //     },
        //     width: 150,
        // },


        { field: "titre", headerName: "Nom", width: 850, renderCell: (params) => {
                return (
                    <>
                        <Link
                            onClick={(e) => onUpdate(e, params.row)}
                            variant="contained"
                        >
                            {params.row.titre}
                        </Link>

                    </>

                );
            } },
        // { field: "rang", headerName: "Rang", width: 100},
        { field: "is_active", headerName: "Visible", width: 100 ,renderCell: (params) => {
            if (params.row.is_active) {
                return (
                    <>
                        <div>Oui</div>
                         {/*<Chip label="Actif" color="primary" variant="outlined" />*/}

                    </>

                );
            }else {
                return (
                    <>
                        <div>Non</div>
                        {/*<Chip label="Désactive" color="error" variant="outlined"/>*/}

                    </>

                );
            }

            } },
        { field: "updatedAt", headerName: "Date de modification", width: 220,renderCell: (params) => {

                return <div >{convertDate(params.row.createdAt)}</div>;
            }},
        {
            field: "_id",
            headerName: 'Rang',
            renderCell: (params) => {
                return (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button onClick={() => {
                                updateRank(params.row._id, parseInt(params.row.rangN)  - 1);
                            }}>⬆️</Button>
                            <div>{params.row.rangN + 1}</div>
                            <Button onClick={() => {
                                updateRank(params.row._id, parseInt(params.row.rangN)  + 1);
                            }}>⬇️</Button>
                        </div>
                    </>
                );
            },
            width: 150,
        },

    ];

  return (
      <div style={{ height: '100%', maxWidth: '100%', margin: 'auto', overflow: 'auto' }}>
          <DataGrid
              rows={dataTable}
              getRowId={(row) => row._id}
              columns={columns}
              hideFooter={true}
              style={{ width: '100%' }}
              // getRowHeight={() => 100}
              autoHeight={true}
              // autoHeight={true}
          />
      </div>
    //   <div >
    //       <DataGrid
    //           rows={dataTable}
    //           getRowId={(row) => row._id}
    //           columns={columns}
    //           hideFooter={true}
    //           // getRowHeight={() => 'auto'}
    //           style={{ maxHeight: '100%', overflow: 'hidden' }}
    //       />
    //   </div>
  );
}
