// import {Navigate, useNavigate} from "react-router-dom";
// import {useEffect} from "react";
//
// /**
//  * Systéme de route protégé avec redirection vers la page de login si l'utilisateur n'as pas set son isSignedIn.
//  * @param {*} param0
//  * @returns
//  */
// export default function ProtectedRoute({ isSignedIn, children }) {
//   let navigate = useNavigate();
//   useEffect(() => {
//     console.log("protect route")
//     console.log(isSignedIn)
//     if (localStorage.getItem("token")){
//       isSignedIn=true;
//       if (!isSignedIn) {
//         navigate("/");
//       }
//     }
//
//   }, [isSignedIn]);
//
//   return <>{children}</>;
// }
import React, {createContext, useEffect, useState} from "react";
import Authentication from "../../pages/Authentication/Authentication";
import { AES, enc } from 'crypto-js';
import FicheV2 from "../../pages/FicheFront/FicheV2";
import Step2 from "../../pages/FicheFront/Step2";
import AuthenticationConseiller from "../../pages/Authentication/AuthenticationConseiller";
import {useLocation} from "react-router-dom";
// export const AuthContext = createContext();
//
// export const AuthProvider = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [token, setToken] = useState(null);
//   const [userInfo, setUserInfo] = useState(null);
//
//   const authenticate = (jwt, user) => {
//     setIsAuthenticated(true);
//     setToken(jwt);
//     setUserInfo(user);
//
//   };
//   useEffect(() => {
//     console.log("Re-rendering with isAuthenticated:", isAuthenticated);
//   }, [isAuthenticated]);
//
//   const signout = () => {
//     setIsAuthenticated(false);
//     setToken(null);
//     setUserInfo(null);
//   };
//   console.log("*****************protect*************************")
//   console.log(isAuthenticated)
//   console.log(token)
//   console.log(userInfo)
//   // return (
//   //     <AuthContext.Provider value={{ isAuthenticated, authenticate, signout, token, userInfo }}>
//   //       {isAuthenticated && children}
//   //     </AuthContext.Provider>
//   // );
//   // return (
//   //     <AuthContext.Provider value={{ isAuthenticated, authenticate, signout, token, userInfo }}>
//   //       <Authentication authenticate={authenticate} />
//   //     </AuthContext.Provider>
//   // );
//   return (
//       <AuthContext.Provider value={{ isAuthenticated, authenticate, signout, token, userInfo }}>
//         {isAuthenticated ? children : <Authentication authenticate={authenticate} />}
//       </AuthContext.Provider>
//   );
// };
export const AuthContext = createContext();
//process.env.REACT_APP_CRYPTO_KEY
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [categoriePreviews,setCategoriePreviews]=useState([]);
  const location = useLocation();

  useEffect(() => {
    const encryptedToken = localStorage.getItem('token');
    const encryptedUserInfo = localStorage.getItem('userInfo');
    if (encryptedToken && encryptedUserInfo) {
      try {
        const decryptedToken = AES.decrypt(encryptedToken, process.env.REACT_APP_CRYPTO_KEY).toString();
        const decryptedUserInfo = AES.decrypt(encryptedUserInfo, process.env.REACT_APP_CRYPTO_KEY).toString();
        setIsAuthenticated(true);
        setToken(decryptedToken);
        setUserInfo(JSON.stringify(decryptedUserInfo));
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const categoriePrev = (cat)=>{

    setCategoriePreviews(cat)
  }
  const authenticate = (jwt, user) => {
    setIsAuthenticated(true);
    setToken(jwt);
    setUserInfo(user);

    try {
      const encryptedToken = AES.encrypt(jwt, process.env.REACT_APP_CRYPTO_KEY).toString();
      const encryptedUserInfo = AES.encrypt(JSON.stringify(user), process.env.REACT_APP_CRYPTO_KEY).toString();
      localStorage.setItem('token', encryptedToken);
      localStorage.setItem('userInfo', encryptedUserInfo);
    } catch (error) {
      console.error(error);
    }
  };


  const signout = () => {
    setIsAuthenticated(false);
    setToken(null);
    setUserInfo(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
  };


  if(children.type.name === "AuthenticationConseiller" || children.type.name === "FicheV2"  || children.type.name === "Step2" || location.pathname === "/" ){
    return (
        <AuthContext.Provider value={{ isAuthenticated, authenticate, signout, token, userInfo,categoriePrev,categoriePreviews }}>
          {isAuthenticated ? children : <AuthenticationConseiller authenticate={authenticate} />}
        </AuthContext.Provider>
    );
  }else {
    return (
        <AuthContext.Provider value={{ isAuthenticated, authenticate, signout, token, userInfo,categoriePrev,categoriePreviews }}>
          {isAuthenticated ? children : <Authentication authenticate={authenticate} />}
        </AuthContext.Provider>
    );
  }

};