import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import {Button, Grid} from "@mui/material";
import {exportFichesByBranch, exportProfilsByBranches} from "../api/Api.jsx";
import {useTheme} from "@mui/material/styles";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const TableChartRepartition = ({ data, startDate,endDate }) => {
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [selectedProfileName, setSelectedProfileName] = useState(null);
    const [filter, setFilter] = useState('');
    const [selectedBranch, setSelectedBranch] = useState(null);


    const allProfiles = useMemo(() => {
        const profiles = new Set();
        data.forEach(item => {
            item.Profils.forEach(profil => {
                profiles.add(profil.Profil);
            });
        });
        return profiles;
    }, [data]);
    const chartData = useMemo(() => {
        // First, create a Set of all unique profiles
        const allProfiles = new Set();
        data.forEach((item) => {
            item.Profils.forEach((profil) => {
                allProfiles.add(profil.Profil);
            });
        });

        // Log all profiles
        console.log(Array.from(allProfiles));

        // Then, map over your data and for each item, create a new object that includes the Branch and a property for each profile with its count (or 0 if the profile does not exist in this item)
        return data.map((item) => {
            const newItem = { Branch: item.Branch };

            allProfiles.forEach((profileName) => {
                const profile = item.Profils.find((profil) => profil.Profil === profileName);
                newItem[profileName] = profile ? profile.Count : 0;
            });

            return newItem;
        });
    }, [data]);


    const handleBarClick = (bar, data, profil) => {
        setSelectedProfile(profil);
        setSelectedProfileName(profil.Profil);
        setSelectedBranch(bar.Branch);  // Add this line
    };
    function handleDownloadClick() {
        exportFichesByBranch(startDate, endDate)
            .then(response => {
                console.log('Fichier Excel téléchargé avec succès');
            })
            .catch(error => {
                console.error('Erreur lors du téléchargement du fichier Excel:', error);
            });
    }


    const handleChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredLinks = useMemo(() => selectedProfile?.Liens.map((lien, index) => ({
        id: index,
        ...lien
    })).filter(lien => lien.Liens.toLowerCase().includes(filter.toLowerCase())) || [], [selectedProfile, filter]);

    const columns = useMemo(() => [
        { field: 'Liens', headerName: 'Métier', flex: 2 },
        { field: 'Count', headerName: 'Nombre', type: 'number', flex: 1 },
        { field: 'Repartition', headerName: 'Répartition', type: 'number', flex: 1 },
    ], []);
    if (chartData.length === 0) {
        return <p>Aucun résultat.</p>
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <p>Détail par branche</p>
                <BarChart
                    style={{ position: 'relative', top: '70px' }}
                    width={600}
                    height={600}
                    data={chartData}
                    layout="vertical"
                    margin={{
                        top: 20, right: 30, left: 50, bottom: 50,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis
                        type="category"
                        dataKey="Branch"
                        width={200}
                        style={{ whiteSpace: 'pre-wrap', overflow: 'hidden' }}
                        // tickFormatter={(value) => {
                        //     if (value.length > 10) {
                        //         return `${value.slice(0, 10)}...`;
                        //     } else {
                        //         return value;
                        //     }
                        // }}
                    />

                    <Tooltip />
                    <Legend />
                    {Array.from(allProfiles).map((profileName) =>
                        <Bar
                            key={profileName}
                            dataKey={profileName}
                            stackId="a"
                            fill={COLORS[profileName.length % COLORS.length]}
                            onClick={(bar) => {
                                const barData = data.find(item => item.Branch === bar.Branch);
                                const profil = barData.Profils.find((profil) => profil.Profil === profileName);
                                handleBarClick(bar, barData, profil);
                            }}

                        />
                    )}

                </BarChart>
            </Grid>
            <Grid item xs={6}>
                {selectedProfile && (
                    <div style={{marginLeft: '20px' }}>
                        <p>Détail par fiches
                            <br/>
                        « {selectedBranch} »
                            <br/>
                            pour « {selectedProfileName} »
                            </p>
                        <div style={{ height: 400, maxWidth: '100%',position:'relative' }}>
                            <DataGrid rows={filteredLinks} columns={columns} hideFooter={true} autoHeight/>
                        </div>
                    </div>
                )}
            </Grid>

        </Grid>
        // <div style={{ display: 'flex'}}>





        // </div>
    );
};



export default TableChartRepartition;
