import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getOneDelegation} from "../../api/Api";
import Skeleton from "@mui/material/Skeleton";
import {Grid} from "@mui/material";
import PreviewModule from "../../components/PreviewModule/PreviewModule";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";


export default function PreviewDelegation () {
    const { delegationId } = useParams();
    const [salutation,setSalutation]=useState();
    const [signature,setSignature]=useState();
    const [existingImage,setExistingImage]=useState("");
    const [link,setLink]=useState("");
    const [loadingStatus, setLoadingStatus] = useState(true);
    useEffect(() => {
        // Fetch existing category data using the id passed as prop
            getOneDelegation(delegationId).then(r => {
                console.log("***********************")
                console.log(r)
                console.log("************************")
                if (r.status === 200) {
                    setSignature(r.data.signature_delegation)
                    setSalutation(r.data.salutation)
                    setLink(r.data.link_image)
                    setExistingImage(r.data.imageData)
                    setLoadingStatus(false);
                }
            });

    }, []);

    const SkeletonTable = <LoadingIndicator
    />
    let liensData = [
        { web: "test.com", titre: "Test 1 " },
        { web: "test.com", titre: "Test 2" },
    ];
    return(
        <>
            {loadingStatus === true ? (
                SkeletonTable
            ) : (
                <>
                    <Grid container>
                        <Grid item xs={4}>

                        </Grid>
                        <Grid item xs={4}>
                        <PreviewModule
                            liens={liensData}
                            salutation={salutation}
                            signature={signature}
                            delegationId={delegationId}
                            image_link={link}
                            image={existingImage}
                        />
                        </Grid>
                        <Grid item xs={4}>

                        </Grid>

                    </Grid>
                </>
            )}
            </>
    )


}