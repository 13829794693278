import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";
import {
    apiSenPassword,
    check,
    deleteCategories,
    getCategories,
    getDelegation,
    getLoginStatus,
    getStats
} from "../../api/Api";
import Layout from "../../layout/Layout";
import LayoutFront from "../../layout/LayoutFront";
import { Controller, useForm } from "react-hook-form";
import { Grid, InputLabel, Stack, TextField, Typography , InputAdornment, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import MySwal from "sweetalert2";

import jwtDecode from "jwt-decode";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function PasswordApi() {
    const navigate = useNavigate();
    const { userId, token } = useParams();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const { register, control, formState: { errors }, handleSubmit } = useForm();
    const [password, setPassword] = useState("");
    const [reType, setRetype] = useState("");
    const [error , setError]=useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const passwordValidation = (value) => {
        // Validate password length
        if (value.length < 8) {
            return "Le mot de passe doit comporter au moins 8 caractères.";
        }

        // Validate that password has at least 1 uppercase letter, 1 lowercase letter, and 1 special character
        const uppercase = /[A-Z]/;
        const lowercase = /[a-z]/;
        const special = /[!@#$%^&*]/;

        if (!uppercase.test(value) || !lowercase.test(value) || !special.test(value)) {
            return "Le mot de passe doit être constitué de 8 caractères au minimum et contenir au moins 1 majuscule, 1 minuscule et 1 caractère spécial.";
        }
    };

    const reTypeValidation = (value) => {
        // Validate that re-typed password matches the original password
        if (value !== password) {
            return "Les mots de passe doivent correspondre.";
        }
    };

    useEffect(() => {
        check(userId,token).then(r=>{
            if(r.status === 200){
                setError(false)
            }else {
                setError(true)
            }
        })

    }, [])

    const onSubmit = (data) => {


          MySwal.fire({
            title: 'Voulez-vous confirmer votre action ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Valider',
            denyButtonText: `Annuler`,
            confirmButtonColor: '#00a79b',
            denyButtonColor: '#BABABA',
        }).then((result) => {
              apiSenPassword(data,userId,token).then(async r => {
                  console.log(r)
                if (r.status === 200) {
                    if(r.data.role === "admin"){
                        await navigate('/gestion')
                    }
                    else {
                        await navigate('/')
                    }
                }else {
                    await MySwal.fire({
                        title: 'Lien expiré',
                        icon: 'error',
                        confirmButtonColor: '#00a79b',
                        denyButtonColor:'#00a79b'

                    })
                }
            });
        })


    };

    return (
        <LayoutFront titre={"Nouveau mot de passe"}>
            <>
                {error === true ? (
                    <Grid container alignItems="center" justifyContent="center" rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
                        <Grid item xs={12} alignItems="center" justifyContent="center" className="boxAjustement">
                            <p style={{ marginTop: '10px',paddingTop:"30px" }}>Le lien a expiré, merci de contacter votre administrateur.</p>
                        </Grid>
                    </Grid>

                ) : (
                    <>
            <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: "50vh" }} rowSpacing={5} columnSpacing={{ xs: 4, sm: 4, md: 4 }}>
                <form className="borderForm" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container alignItems="center" justifyContent="center" rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
                        <Grid item xs={12} alignItems="center" justifyContent="center" className="boxAjustement">
                            <Controller
                                name="userId"
                                control={control}
                                defaultValue={userId}
                                render={({ field }) => <input type="hidden" {...field} />}
                            />
                            <Controller
                                name="token"
                                control={control}
                                defaultValue={token}
                                render={({ field }) => <input type="hidden" {...field} />}
                            />
                            <Controller
                                name="password"
                                control={control}
                                defaultValue={password}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <TextField
                                            label="Mot de passe"
                                            id="outlined"
                                            name="titre"
                                            className="boxForm"
                                            type={showPassword ? 'text' : 'password'}
                                            value={value}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                onChange(e);
                                            }}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <p style={{ marginTop: '10px' }}>
                                            Le mot de passe doit être constitué de 8 caractères au minimum et contenir au moins 1 majuscule, 1 minuscule et 1 caractère spécial.
                                        </p>
                                    </>
                                )}
                                rules={{ validate: passwordValidation }}
                            />

                        </Grid>

                        <Grid item xs={12} alignItems="center" justifyContent="center" className="boxAjustement">
                            <Controller
                                name="reType"
                                control={control}
                                defaultValue={reType}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label="Retaper le mot de passe"
                                        id="outlined"
                                        name="reType"
                                        className="boxForm"
                                        type={showPassword ? 'text' : 'password'}
                                        value={value}
                                        onChange={(e) => {
                                            setRetype(e.target.value);
                                            onChange(e);
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                                rules={{ validate: reTypeValidation }}
                            />

                        </Grid>

                        <Grid item xs={12} alignItems="center" justifyContent="center" className="boxAjustement">
                            <Button className="with-after_icon-akto btn-primary-filled-step2" type="submit">Soumettre</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
                    </>

                )}
            </>


        </LayoutFront>
    );
}

