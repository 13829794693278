import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute.jsx";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";
import {
    exportFichesByBranch,
    exportProfilsByBranches,
    ficheParTypologie,
    getDelegation,
    getFichesByBranch,
    getStats
} from "../../api/Api.jsx";
import {Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import DatePicker from "react-datepicker";
import Button from "@mui/material/Button";
import PieChartProfilsEnvoieMail from "../../components/PieChartProfilsEnvoieMail.jsx";
import Popularite from "../../components/PopulariteChart.jsx";
import TableChartRepartition from "../../components/TableChartRepartition.jsx";
import {useTheme} from "@mui/material/styles";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import Box from "@mui/material/Box";
registerLocale('fr', fr)
setDefaultLocale('fr');

export default function StatProfils() {
    const [stats,setStats]=useState([]);
    const { isAuthenticated } = useContext(AuthContext);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const SkeletonTable = <LoadingIndicator
    />
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dataFilterByDate,setDataFilterByDate]=useState([])
    const [delegation,setDelegation]=useState([]);
    const [viewsCheckBoxDelegation,setViewsCheckBoxDelegation]=useState(false)
    const [dataFilter,setDataFilter]=useState()
    const [isChecked ,setIsChecked]=useState(false);
    const [envoieDelegation, setEnvoieDelegation]=useState();
    const [allData,setAllData]=useState();
    const theme = useTheme();
    function filterDates(startDate, endDate, data) {
        return data.filter(item => {
            const itemDate = new Date(item.date);
            return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
        });
    }
    const filteredDataByRegion = (selectedDelegations) => {
        console.log("///////////////")
        console.log(selectedDelegations)
        console.log(dataFilterByDate)
        console.log("///////////////")
        return dataFilterByDate.filter(item => selectedDelegations.includes(item.region._id));
    };
    const valideDate = () => {
        setIsChecked(false);
        const checkboxStates = {};
        selectedDelegations.forEach(id => {
            checkboxStates[id] = true;
        });

        let data = filterDates(startDate.toISOString(), endDate.toISOString(), stats);
        setDataFilterByDate(data);

        getDelegation()
            .then(resp => {
                setDelegation(resp.data);

                const newSelectedDelegations = resp.data.filter(item => checkboxStates[item.id]);
                setSelectedDelegations(newSelectedDelegations);

                setViewsCheckBoxDelegation(true);

                // Appeler filteredDataByRegion ici pour obtenir les données filtrées
                const filteredData = filteredDataByRegion(newSelectedDelegations.map(delegation => delegation._id));
                setDataFilter(filteredData);
            })
            .catch(err => {
                // Gérer les erreurs ici
            });
    };



    const [selectedDelegations, setSelectedDelegations] = useState([]);
    const handleChangeStartDate = (date) => {
        setStartDate(date);

        // Si la date de fin est antérieure à la date de début, la mettre à jour
        if (endDate < date) {
            setEndDate(date);
        }
    };

    const handleChangeEndDate = (date) => {
        // Si la date de fin est antérieure à la date de début, empêcher la mise à jour
        if (date >= startDate) {
            setEndDate(date);
        }
    };
    const handleChange = (event) => {
        const delegationId = event.target.value;
        let data=[delegationId];
        let data2 = [...selectedDelegations];
        if (selectedDelegations.includes(delegationId)) {
            setSelectedDelegations(selectedDelegations.filter(id => id !== delegationId));
        } else {
            setSelectedDelegations([...selectedDelegations, delegationId]);

        }
        data = [...data, ...selectedDelegations];

        let dd=filteredDataByRegion(data)
        setDataFilter(dd)


    };

    const handleSelectAll = (event) => {
        event.preventDefault();  // ajouté cette ligne
        setSelectedDelegations(delegation.map(delegation => delegation._id));
        setIsChecked(true);
    };

    const handleReset = () => {
        setSelectedDelegations([]);
        setIsChecked(false);
    };

    useEffect(() => {


    }, [])
    useEffect(() => {
        setIsChecked(selectedDelegations.length > 0)
        ficheParTypologie(startDate,endDate,selectedDelegations)
            .then(resp => {
                setEnvoieDelegation(resp.data)
            }).catch(err => {

        })
        getFichesByBranch(startDate,endDate,selectedDelegations)
            .then(resp => {
                setAllData(resp.data)
            }).catch(err => {

        })


    }, [selectedDelegations]);

    function handleDownloadClick() {
        exportFichesByBranch(startDate, endDate)
            .then(response => {
                console.log('Fichier Excel téléchargé avec succès');
            })
            .catch(error => {
                console.error('Erreur lors du téléchargement du fichier Excel:', error);
            });
    }
    const sortedDelegations = [...delegation].sort((a, b) => a.nom_delegation.localeCompare(b.nom_delegation));
    const numCols = 3; // nombre de colonnes que vous voulez
    const delegationsPerCol = Math.ceil(sortedDelegations.length / numCols); // nombre de délégations par colonne

// diviser les délégations en sous-tableaux pour chaque colonne
    const delegationsInCols = [];
    for (let i = 0; i < sortedDelegations.length; i += delegationsPerCol) {
        delegationsInCols.push(sortedDelegations.slice(i, i + delegationsPerCol));
    }
    console.log("**********************");
    console.log(dataFilter);
    console.log("**********************");

    return(
        <>
            <Grid container spacing={5} justify="center" alignItems="center" className="gridCustom2">
                <Grid item xs={12} style={{textAlign:"center"}}>
                    <Typography variant="h6" style={{paddingBottom:'20px'}}>Définition de la période</Typography>
                </Grid>
                <Grid item  style={{paddingTop:"10px"}}>
                    <Typography>Date de début de période</Typography>
                    <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={handleChangeStartDate}
                        dateFormat="dd/MM/yyyy"
                        locale="fr"
                    />
                </Grid>
                <Grid item  style={{paddingTop:"10px"}}>
                    <Typography>Date de fin de période</Typography>
                    <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={handleChangeEndDate}
                        dateFormat="dd/MM/yyyy"
                        minDate={startDate}
                        locale="fr"// Empêcher la sélection de dates antérieures à la date de début
                    />
                </Grid>
                <Grid item>
                    <Button className="with-after_icon-akto btn-primary-filled" onClick={() => valideDate()}>Valider</Button>
                </Grid>
            </Grid>
            {/*<Grid container spacing={5} justify="center" alignItems="center" className="gridCustom2" style={{paddingTop:"20px"}}>*/}

            {/*</Grid>*/}
            { viewsCheckBoxDelegation &&
                <Grid container spacing={5} justify="center" alignItems="center" className="gridCustom2" style={{marginTop:"0px",paddingBottom:"50px"}}>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <Typography variant="h6" style={{paddingBottom:'20px'}}>Sélection des délégations</Typography>
                    </Grid>

                    <Grid container justify="center" style={{paddingTop:"10px", padding: '0 16px',width: '50%',position:'relative',left:'104px'}}>
                        {delegationsInCols.map((delegationsInCol, colIndex) => (
                            <Grid item xs={12} sm={4} key={colIndex} style={{ width: '33.33%' }}>
                                {delegationsInCol.map((delegation) => (
                                    <Grid item xs={12} key={delegation._id}>
                                        <FormControlLabel
                                            control={<Checkbox checked={selectedDelegations.includes(delegation._id)} onChange={handleChange} value={delegation._id} />}
                                            label={delegation.nom_delegation}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>



                    <Grid item xs={12} style={{textAlign:"center",paddingTop:"0px"}}>

                        <a
                            href="#"
                            onClick={(event) =>   // ajouté 'event' ici
                                selectedDelegations.length === delegation.length
                                    ? handleReset
                                    : handleSelectAll(event)  // passer 'event' ici
                            }
                        >
                            {selectedDelegations.length === delegation.length
                                ? "Réinitialiser la sélection"
                                : "Sélectionner toutes les régions"}
                        </a>
                    </Grid>
                </Grid>
            }
            { isChecked &&
                <Box width={1}>


                <Grid  spacing={5} justify="center" alignItems="center" direction="column" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <Grid item xs={12} sm={6} md={4} className="gridCustomStat" >
                        <Grid  spacing={0} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h6">Répartition des envois (e-mails)</Typography>
                            </Grid>
                            <Grid item xs={4} sm={6} md={12}>
                            <PieChartProfilsEnvoieMail data={envoieDelegation}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gridCustomStat" style={{paddingTop:'40px'}}>
                        <Grid spacing={5} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{marginBottom:'10px'}}>Popularité</Typography>
                            </Grid>
                            <Grid item xs={12} className="center-content">
                                <Popularite startDate={startDate}endDate={endDate}selectedDelegations={selectedDelegations}/>
                            </Grid>

                        </Grid>
                        <Grid spacing={5} justify="center" alignItems="center" style={{paddingTop:'50px'}}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Répartition par profils</Typography>
                            </Grid>
                            <Grid item xs={12} className="center-content">
                                <TableChartRepartition data={allData}/>
                            </Grid>
                            <Grid item xs={12} className="center-content">

                            </Grid>
                            <Grid spacing={0} style={{position:'absolute'}}>
                                <Button
                                    className="with-after_icon-akto btn-primary-filled"
                                    onClick={handleDownloadClick}
                                    style={{ marginTop: theme.spacing(2),top:'100px',left:'145px' }}
                                >
                                    Télécharger la liste complète
                                </Button>
                                <br/>
                                <br/>
                                <br/>
                            </Grid>


                        </Grid>
                    </Grid>
                </Grid>
                </Box>
            }
        </>
    )
}