import React, { useState, useEffect } from 'react';
import Handlebars from 'handlebars';
import {fetchPreview} from "../../api/Api";

export default function PreviewModule(props) {
    const [template, setTemplate] = useState('');
    const [data, setData] = useState({});

    useEffect(() => {
        fetchPreview(props.liens, props.salutation, props.signature,props.delegationId,props.image_link,props.image)
            .then(({ template, data }) => {
                setTemplate(template);
                setData(data);
            });
    }, []);

    if (!template) {
        return null;
    }

    const compiledTemplate = Handlebars.compile(template);
    const html = compiledTemplate(data);
    const innerHTML = { __html : html }

    return( <div dangerouslySetInnerHTML={innerHTML} /> )
}
