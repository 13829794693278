import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { Alert, AlertTitle } from "@mui/material";
import {useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from "../../security/auth";
import {loginUser} from "../../api/Api";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";
import Layout from "../../layout/Layout";
import LoadingIndicatorLogin from "../../components/LoadingIndicatorLogin.jsx";




export default function Authentication() {

    /**
     * Etat des différentes alertes de connections.
     */
    const [ showAlerte, setShowAlerte ] = useState(false);
    const [ showValidation, setShowValidation] = useState(false);
    const { token, isAuthenticated, signout ,userInfo,authenticate} = useContext(AuthContext);
    const [wait,setWait]=useState(false)




    /**
     * Initilisation du hook de navigation, équivalent du Redirect de wouter.
     */
    let navigate = useNavigate();

    /**
     * Message d'erreur lors de la connexion.
     */
    const Alerte = () => (
      <Alert severity="error">
        <AlertTitle>Erreur de connexion</AlertTitle>
        <strong>E-mail ou mot de passe incorrect.</strong>
      </Alert>
    );

    /**
     * Message de succés de l'authentification
     */
    const Validation = () => (
      <Alert severity="success">
        <AlertTitle>Succès de l'authentification</AlertTitle>
      </Alert>
    );


    const handleSubmit = async (event) => {
        event.preventDefault();
        setWait(true);
        const data = new FormData(event.currentTarget);
        const email = data.get("email");
        const password = data.get("password");


        try {
            await loginUser({ email, password }).then(async r => {

                if (r.status === 200) {
                    setWait(false)
                    setShowAlerte(false);

                    authenticate(r.data.token,r.data.user_info)


                    // setToken(r.data.token);
                    // setUserInfo(r.data.user_info);
                    // setIsAuthenticated(true);
                     await localStorage.setItem("token", r.data.token);
                    // await localStorage.setItem("user_info", JSON.stringify(r.data.user_info));
                    // await localStorage.setItem("acces",true)
                        if(r.data.role ==="admin" || r.data.role === "superadmin"){
                            setShowValidation(true)
                                setTimeout(() => {
                                navigate("/delegations");
                            }, 2000);
                        }else if(r.data.role ==="conseiller"){
                            setWait(false)
                            setShowValidation(false)

                        }
                        // setTimeout(() => {
                        //     navigate("/liens");
                        // }, 2000);

                   // actions.updateName({token: token})

                   // return true


                }else {
                    setWait(false)
                    setShowAlerte(true);
                }
            });

        } catch (error) {
            setWait(false)
            setShowAlerte(true);
        }
            // await login({ email, password }).then(async r => {
            //     console.log("login")
            //         setShowValidation(true);

            //
            // });

        // } catch (error) {
        //     setShowAlerte(true);
        // }
    };

  return (
      <Layout isAuthenticated={false}>
    <Container component="main" maxWidth="xs">
      {/*<CssBaseline />*/}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Authentification
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresse e-mail"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {showAlerte ? <Alerte /> : null}
          {showValidation ? <Validation /> : null}
            {wait ? <LoadingIndicatorLogin/> : null}
          <Button className="with-after_icon-akto"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Se connecter
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/password-send" variant="body2">
                Mot de passe oublié ?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
      </Layout>
  );
}
