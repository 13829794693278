import {useContext, useEffect, useState} from "react";
import {BrowserRouter, Routes, Route, Navigate, useParams, useNavigate, Router} from "react-router-dom";
import {getDelegation, getLoginStatus} from "../api/Api";
import Layout from "../layout/Layout";
import Authentication from "../pages/Authentication/Authentication";
import Categorie from "../pages/Categorie/Categorie";
import {appThemeAkto} from "../themes/theme";
import {ThemeProvider} from "@mui/material";
import Delegations from "../pages/Delegation/delegations";
import FormulaireDelegations from "../components/CRUD_delegations/formulaireDelegations";
import FormulaireCat from "../components/CRUD_categorie/formulaireCat";
import Typologies from "../pages/Typologies/typologies";
import FormulaireTypologie from "../components/CRUD_typologie/formulaireTypologie";
import UserPage from "../pages/User/userPage";
import FormulaireUser from "../components/CRUD_user/formulaireUser";
import Liens from "../pages/Liens/liens";
import FormulaireLiens from "../components/CRUD_liens/formulaireLiens";
import FicheV2 from "../pages/FicheFront/FicheV2";
import Step2 from "../pages/FicheFront/Step2";
import {AuthContext, AuthProvider} from "../components/ProtectedRoute/ProtectedRoute";
import ModulePreviews from "../pages/PreviewMail/ModulePreviews";
import AuthenticationConseiller from "../pages/Authentication/AuthenticationConseiller";
import passwordApi from "../pages/recovery/passwordApi";
import PasswordApi from "../pages/recovery/passwordApi";
import PreviewDelegation from "../pages/PreviewDelegation/PreviewDelegation";
import Stats from "../pages/Stats/stats";
import PasswordAdm from "../pages/recovery/passwordAdm.jsx";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateFnsUtils from '@mui/lab/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';



export default function Path() {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const {isAuthenticated} = useContext(AuthContext) || {isAuthenticated: true};




    console.log("path ***********************************")
    console.log(isAuthenticated)
    useEffect(() => {
        console.log("Re-rendering with isAuthenticated path :", isAuthenticated);
    }, []);


    return (

        <BrowserRouter>
            <ThemeProvider theme={appThemeAkto}>

                <Routes>
                    <Route path="/" element={
                        <AuthProvider isAuthenticated={true}>
                            <AuthenticationConseiller/>
                        </AuthProvider>
                    }/>
                    <Route path="/gestion" element={
                        <AuthProvider isAuthenticated={true}>
                            <Authentication/>
                        </AuthProvider>
                    }/>

                    <Route path="/password-reset/:userId/:token" element={
                        // <AuthProvider isAuthenticated={true}>
                            <PasswordApi/>
                        // </AuthProvider>
                    }/>
                    <Route path="/password-send" element={
                        // <AuthProvider isAuthenticated={true}>
                        <PasswordAdm/>
                        // </AuthProvider>
                    }/>

                    <Route
                        path="/fiches"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FicheV2/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/step2/:data"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <Step2/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/preview/:delegationId"
                        element={
                           // <AuthProvider isAuthenticated={isAuthenticated}>
                                <PreviewDelegation/>
                           // </AuthProvider>
                        }
                    />

                    <Route
                        path="/categories"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <Categorie/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/categorie/ajout"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireCat/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/categorie/update/:id"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireCat/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/delegations"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <Delegations/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/delegations/ajout"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireDelegations/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/delegations/update/:id"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireDelegations/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/profils"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <Typologies/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/profils/ajout"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireTypologie/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/profils/update/:id"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireTypologie/>
                            </AuthProvider>
                        }
                    />

                    <Route
                        path="/comptes"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <UserPage/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/comptes/ajout"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireUser/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/comptes/update/:id"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireUser/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/documents"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <Liens/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/documents/ajout"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireLiens/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/documents/update/:id"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <FormulaireLiens/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/test"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <ModulePreviews/>
                            </AuthProvider>
                        }
                    />
                    <Route
                        path="/Statistiques"
                        element={
                            <AuthProvider isAuthenticated={isAuthenticated}>
                                <Stats/>
                            </AuthProvider>
                        }
                    />


                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>


            </ThemeProvider>
        </BrowserRouter>

    );
}
