import { useState } from 'react';
import {loginUser} from "../api/Api";
import {useNavigate} from "react-router-dom";

const useAuth = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('user_info')));
    const [isAuthenticated, setIsAuthenticated] = useState(!!token);
    const navigate = useNavigate();

    const login = async (email, password) => {
        try {
            await loginUser({ email, password }).then(async r => {
                if (r.status === 200) {
                    console.log("reponse")
                    console.log(r)
                    console.log(r.status === 200);

                        setToken(r.data.token);
                        setUserInfo(r.data.user_info);
                        setIsAuthenticated(true);
                       await localStorage.setItem("token", r.data.token);
                       await localStorage.setItem("user_info", JSON.stringify(r.data.user_info));

                    return true


                }
            });

        } catch (error) {
            console.log(error);
        }
    };

    const logout = async () => {
        setToken(null);
        setUserInfo(null);
        setIsAuthenticated(false);
        await localStorage.removeItem("token");
        await localStorage.removeItem("user_info");
        // localStorage.setItem("isSigned","false")
       await navigate('/');
    };

    return { token, userInfo, isAuthenticated, login, logout };
};

export default useAuth;

