import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {Controller, useForm} from "react-hook-form";
import {Alert, AlertTitle, InputLabel, MenuItem, Select} from "@mui/material";
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useAuth from "../../security/auth";
import {getDelegation, loginConseiller, loginUser} from "../../api/Api";
import {AuthContext} from "../../components/ProtectedRoute/ProtectedRoute";
import Layout from "../../layout/Layout";
import Skeleton from "@mui/material/Skeleton";
import LayoutFront from "../../layout/LayoutFront";
import LoadingIndicator from "../../components/LoadingIndicator.jsx";
import {tr} from "date-fns/locale";
import LoadingIndicatorLogin from "../../components/LoadingIndicatorLogin.jsx";


export default function AuthenticationConseiller() {

    /**
     * Etat des différentes alertes de connections.
     */
    const {register, control, formState: {errors}, handleSubmit} = useForm();
    const [showAlerte, setShowAlerte] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const {token, isAuthenticated, signout, userInfo, authenticate} = useContext(AuthContext);
    const [allDelegation, setAllDelegation] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [delegation, setDelegation] = useState("");
    const [password, setPassword] = useState("");
    const [wait,setWait]=useState(false)
    useEffect(() => {
         localStorage.removeItem("token");
         localStorage.removeItem("user_info");

        getDelegation().then(r => {
            if (r.status === 200) {

                setAllDelegation(r.data)
                setLoadingStatus(false);
            }
        });
    }, []);


    /**
     * Initilisation du hook de navigation, équivalent du Redirect de wouter.
     */
    let navigate = useNavigate();

    /**
     * Message d'erreur lors de la connexion.
     */
    const Alerte = () => (
        <Alert severity="error">
            <AlertTitle>Erreur de connexion</AlertTitle>
            <strong>E-mail ou mot de passe incorrect.</strong>
        </Alert>
    );

    /**
     * Message de succés de l'authentification
     */
    const Validation = () => (
        <Alert severity="success">
            <AlertTitle>Succès de l'authentification</AlertTitle>
        </Alert>
    );
    async function handleLogin() {
        try {
            const r = await loginConseiller({ delegation, password });

            if (r.status === 200) {
                setShowAlerte(false);

                authenticate(r.data.token, r.data.user_info);
                await localStorage.setItem("token", r.data.token);

                if (r.data.role === "conseiller") {
                    setShowValidation(true);
                    setTimeout(() => {
                        navigate("/fiches");
                    }, 2000);
                }
            } else {
                setShowAlerte(true);
            }
        } catch (error) {
            setShowAlerte(true);
        }
    }


    const onSubmit = async (d, e) => {
        e.preventDefault();
        setWait(true);
        // const data = new FormData(d);
        const delegation = d.delegation;
        const password = d.password;

        // console.log(data)

        try {
            await loginConseiller({delegation, password}).then(async r => {

                if (r.status === 200) {
                    setShowAlerte(false);

                    authenticate(r.data.token, r.data.user_info)
                    await localStorage.setItem("token", r.data.token);

                    if (r.data.role === "conseiller") {
                        setWait(false)
                        setShowValidation(true)
                        setTimeout(() => {

                            navigate("/fiches");
                        }, 2000);
                    }


                } else {
                    setWait(false)
                    setShowAlerte(true);
                }
            });

        } catch (error) {
                console.log(error)
            setWait(false)
            setShowAlerte(true);
        }

    };
    const SkeletonTable = <Skeleton sx={{bgcolor: 'grey.900'}}
                                    variant="rectangular"
                                    width={210}
                                    height={118}
    />

    return (
        <LayoutFront isAuthenticated={true}>
            <>
                {loadingStatus === true ? (
                    SkeletonTable
                ) : (
                    <>
                        <Container component="main" spacing={0}
                            // direction="column"
                                   alignItems="center"
                                   justifyContent="center"
                                   style={{ minHeight: '50vh'}}
                                   rowSpacing={5}
                                   columnSpacing={{ xs:4, sm: 4, md: 4 }}>
                            {/*<CssBaseline />*/}

                            <Typography component="h1" variant="h5">
                                Authentification
                            </Typography>

                            <form className="borderForm" onSubmit={handleSubmit(onSubmit)}>
                                <Grid container

                                >
                                    <Grid item xs={12} alignItems="center"
                                          justifyContent="center" className="boxAjustement">
                                        <InputLabel>Nom de la délégation</InputLabel>
                                        <Controller
                                            name="delegation"
                                            control={control}
                                            defaultValue={delegation}
                                            render={({field: {onChange, value}, fieldState: {error}}) => (
                                                <Select
                                                    value={value}
                                                    label="Nom de la délégation"
                                                    className="boxForm"
                                                    onChange={onChange}
                                                    // style={{minWidth: 270}}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    FormHelperTextProps={{
                                                        component: 'div',
                                                    }}
                                                >
                                                    {allDelegation.map((option, index) => {

                                                            if(option.is_active){
                                                                return (
                                                                    <MenuItem key={index} value={option._id}>
                                                                        {option.nom_delegation}
                                                                    </MenuItem>
                                                                )
                                                            }

                                                        }
                                                    )}
                                                </Select>
                                            )}
                                            rules={{required: "L'email est obligatoire"}}
                                        />
                                    </Grid>
                                    <Controller
                                        name="password"
                                        control={control}
                                        defaultValue={password}
                                        render={({field: {onChange, value}, fieldState: {error}}) => (
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="password"
                                                label="Mot de passe"
                                                type="password"
                                                id="password"
                                                autoComplete="off"
                                                value={value}
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                        rules={{required: 'Le mot de passe est obligatoire'}}
                                    />
                                    {showAlerte ? <Alerte/> : null}
                                    {showValidation ? <Validation/> : null}
                                    {wait ? <LoadingIndicatorLogin/> : null}

                                    <Button
                                        className="with-after_icon-akto"
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                    >
                                        Se connecter
                                    </Button>
                                    {/*<Grid container>*/}
                                    {/*    <Grid item xs>*/}
                                    {/*        <Link href="#" variant="body2">*/}
                                    {/*            Mot de passe oublié ?*/}
                                    {/*        </Link>*/}
                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </form>
                        </Container>

                    </>
                )}

            </>
        < /LayoutFront>
    )
}
